/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Button, Col, ButtonToolbar, Card, Image } from 'react-bootstrap';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { useFormik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { FaRedoAlt } from '@react-icons/all-files/fa/FaRedoAlt';
import PageContainer from '../../hoc/PageContainer';
import RR1 from '../../assets/img/rr-1.png';
import RR2 from '../../assets/img/rr-2.png';
import RR3 from '../../assets/img/rr-3.png';
import RR4 from '../../assets/img/rr-4.png';
import RR5 from '../../assets/img/rr-5.png';
import CAL from '../../assets/img/Icon-cal.png';
import {
  usePurchaseOrder,
  useRecyclers,
  useRecyclerPaymentTermsAndCreditLimit,
} from '../../hooks/hooks';
import { useAsyncEffect, useStakeholders } from '../../hooks';
import { hasError, toLocalDate, toRupees, toServerDate } from '../../shared/utils/Helper';
import DeletePoModal from '../../features/OrderManagement/PurchaseOrder/DeletePoModal';
import RecyclerReceivablesTableNew from '../../features/RecyclerReceivables/RecyclerReceivablesTableNew';
import ManageRecyclerList from '../RecyclersPage/ManageRecyclerList';
import { pick } from 'lodash';

const RecyclerReceivablesPage = (props) => {
  const [recyclerList, setRecyclerList] = useState([]);
  const [receivablesData, setReceivablesData] = useState([]);
  const [recyclerReceivablesList, setRecyclerReceivablesList] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [recList, setRecList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const [openingBalance, setOpeningBalance] = useState(null);
  const [closingBalance, setClosingBalance] = useState(null);
  const [totalAdjustments, setTotalAdjustments] = useState(null);
  const [totalRecepits, setTotalRecepits] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [
    {
      value: { data: recyclers = [] },
      getPError,
      fetchingP,
      getValueP,
    },
    { doFetchRecyclerByPage },
  ] = useRecyclers();

  const [
    { value, error },
    { doGetReceivablesV2, doGetReceivablesMetrics },
  ] = useRecyclerPaymentTermsAndCreditLimit();

  const initialValues = {
    startDate: '',
    endDate: '',
    scheduledTime: '',
  };

  const [refresh, setRefresh] = useState(false);

  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);
  let result = [];

  useAsyncEffect(async () => {
    setFromDate(firstDay);
    setToDate(lastDay);
    const mParams = {
      fromDate: firstDay,
      toDate: lastDay,
    };
    const metricsResult = await doGetReceivablesMetrics(mParams);
    // const recyclers = await doFetchRecyclerByPage();
    // setTotalCount(recyclers?.totalCount);
    // if (recyclers && recyclers.data) {
    //   setRecyclerList(recyclers?.data);
    // } else {
    //   setRecyclerList([]);
    // }
    const rIds = recyclers?.data?.map((i) => i.id).join();
    const input = {
      recyclerIds: rIds,
    };
    result = await doGetReceivablesV2(input);
    if (result && result.status) {
      result = [];
      setLoading(false);
    } else {
      setRecyclerReceivablesList(result);
      setReceivablesData(result);
      setLoading(false);
    }
    let openingBal = 0;
    let totalSale = 0;
    let totalRecepits = 0;
    let totalDebit = 0;
    formik.setFieldValue('startDate', toServerDate(firstDay, false));
    formik.setFieldValue('endDate', toServerDate(lastDay, false));
    formik.setFieldValue(
      'scheduledTime',
      toLocalDate(firstDay, 'DD-MMM-YYYY') + '-' + toLocalDate(lastDay, 'DD-MMM-YYYY')
    );
    let previousEndDate = new Date();
    previousEndDate = previousEndDate.setDate(firstDay.getDate() - 1);
    let previousClosingBalance = 0;
    result?.filter((item) => {
      if (item && item.createdAt != null) {
        if (previousEndDate >= new Date(item.createdAt)) {
          previousClosingBalance += item.finalInvoiceAmount - item.recepits;
        }
      }
    });
    const filteredData = result?.filter((item) => {
      if (item && item.createdAt != null)
        return firstDay <= new Date(item.createdAt) && lastDay >= new Date(item.createdAt);
    });
    setRecyclerReceivablesList(filteredData);
    filteredData?.forEach((item) => {
      if (
        item &&
        item.createdAt != null &&
        firstDay <= new Date(item.createdAt) &&
        lastDay > new Date(item.createdAt)
      ) {
        totalSale += item.totalAmount;
        totalRecepits += item.recepits;
        totalDebit += item.debitNoteAmount;
      }
    });
    setOpeningBalance(toRupees(metricsResult.openingBalance, false));
    setTotalSales(toRupees(metricsResult.totalSales, false));
    setClosingBalance(toRupees(metricsResult.closingBalance, false));
    setTotalAdjustments(toRupees(metricsResult.totalDebits, false));
    setTotalRecepits(toRupees(metricsResult.totalRecepits, false));
  }, []);

  const onFilterChange = async (params) => {
    setLoading(true);
    const mParams = {
      fromDate: firstDay,
      toDate: lastDay,
    };
    const metricsResult = await doGetReceivablesMetrics(mParams);
    const recyclers = await doFetchRecyclerByPage(params);
    setTotalCount(recyclers?.totalCount);
    setRecyclerList(_.flatMap(recyclers.data));
    const rIds = recyclers?.data?.map((i) => i.id).join();
    const input = {
      recyclerIds: rIds,
    };
    result = await doGetReceivablesV2(input);
    if (result && result.status) {
      result = [];
      setLoading(false);
    } else {
      setRecyclerReceivablesList(result);
      setReceivablesData(result);
      setLoading(false);
    }
    const filteredData = result?.filter((item) => {
      if (item && item.createdAt != null)
        return firstDay <= new Date(item.createdAt) && lastDay >= new Date(item.createdAt);
    });
    setRecyclerReceivablesList(filteredData);
  };

  const onDateFilterChange = async (event, picker) => {
    setLoading(true);
    firstDay = picker.startDate;
    lastDay = picker.endDate;
    formik.setFieldValue('startDate', toServerDate(picker.startDate, false));
    formik.setFieldValue('endDate', toServerDate(picker.endDate, false));
    formik.setFieldValue(
      'scheduledTime',
      toLocalDate(picker.startDate, 'DD-MMM-YYYY') +
        '-' +
        toLocalDate(picker.endDate, 'DD-MMM-YYYY')
    );
    firstDay = new Date(firstDay);
    lastDay = new Date(lastDay);
    setFromDate(firstDay);
    setToDate(lastDay);
    const mParams = {
      fromDate: new Date(firstDay),
      toDate: new Date(lastDay),
    };
    const metricsResult = await doGetReceivablesMetrics(mParams);
    let openingBal = 0;
    let totalSale = 0;
    let totalRecepits = 0;
    let totalDebit = 0;
    let previousEndDate = new Date(firstDay);
    previousEndDate = previousEndDate.setDate(previousEndDate.getDate() - 1);
    previousEndDate = new Date(previousEndDate);
    let previousClosingBalance = 0;
    receivablesData?.filter((item) => {
      if (item && item.createdAt != null) {
        if (previousEndDate >= new Date(item.createdAt)) {
          previousClosingBalance += item.finalInvoiceAmount - item.recepits;
        }
      }
    });
    const filteredData = receivablesData.filter((item) => {
      if (item && item.createdAt != null)
        return firstDay <= new Date(item.createdAt) && lastDay >= new Date(item.createdAt);
    });
    setRecyclerReceivablesList(filteredData);
    filteredData.forEach((item) => {
      if (
        item &&
        item.createdAt != null &&
        firstDay <= new Date(item.createdAt) &&
        lastDay >= new Date(item.createdAt)
      ) {
        totalSale += item.totalAmount;
        totalRecepits += item.recepits;
        totalDebit += item.debitNoteAmount;
      }
    });
    setOpeningBalance(toRupees(metricsResult.openingBalance, false));
    setTotalSales(toRupees(metricsResult.totalSales, false));
    setClosingBalance(toRupees(metricsResult.closingBalance, false));
    setTotalAdjustments(toRupees(metricsResult.totalDebits, false));
    setTotalRecepits(toRupees(metricsResult.totalRecepits, false));
    setLoading(false);
  };

  const onResetDateFilter = async (event, picker) => {
    setLoading(true);
    picker.element.val('');
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    firstDay = new Date(y, m, 1);
    lastDay = new Date(y, m + 1, 0);
    setFromDate(firstDay);
    setToDate(lastDay);
    formik.setFieldValue('startDate', toServerDate(firstDay, false));
    formik.setFieldValue('endDate', toServerDate(lastDay, false));
    formik.setFieldValue(
      'scheduledTime',
      toLocalDate(firstDay, 'DD-MMM-YYYY') + '-' + toLocalDate(lastDay, 'DD-MMM-YYYY')
    );
    const mParams = {
      fromDate: new Date(firstDay),
      toDate: new Date(lastDay),
    };
    const metricsResult = await doGetReceivablesMetrics(mParams);
    let openingBal = 0;
    let totalSale = 0;
    let totalRecepits = 0;
    let totalDebit = 0;
    let previousEndDate = new Date(firstDay);
    previousEndDate = previousEndDate.setDate(previousEndDate.getDate() - 1);
    previousEndDate = new Date(previousEndDate);
    let previousClosingBalance = 0;
    receivablesData?.filter((item) => {
      if (item && item.createdAt != null) {
        if (previousEndDate >= new Date(item.createdAt)) {
          previousClosingBalance += item.finalInvoiceAmount - item.recepits;
        }
      }
    });
    const filteredData = receivablesData.filter((item) => {
      if (item && item.createdAt != null)
        return firstDay <= new Date(item.createdAt) && lastDay >= new Date(item.createdAt);
    });
    setRecyclerReceivablesList(filteredData);
    filteredData.forEach((item) => {
      if (
        item &&
        item.createdAt != null &&
        firstDay <= new Date(item.createdAt) &&
        lastDay >= new Date(item.createdAt)
      ) {
        totalSale += item.totalAmount;
        totalRecepits += item.recepits;
        totalDebit += item.debitNoteAmount;
      }
    });
    setOpeningBalance(toRupees(metricsResult.openingBalance, false));
    setTotalSales(toRupees(metricsResult.totalSales, false));
    setClosingBalance(toRupees(metricsResult.closingBalance, false));
    setTotalAdjustments(toRupees(metricsResult.totalDebits, false));
    setTotalRecepits(toRupees(metricsResult.totalRecepits, false));
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,

    onSubmit: (values) => {},
  });

  return (
    <PageContainer>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Row>
          <Col>
            <h4>Recycler Recievables</h4>
            <p className="text-muted">To keep check on different payments</p>
            <hr style={{ borderTop: '1px solid' }} />
          </Col>
        </Row>
        <Row style={{ width: '30%' }}>
          <Col style={{ fontSize: '12px', marginBottom: '15px' }}>
            <span style={{ fontSize: '15px' }}>Date Range</span>
            <DateRangePicker
              initialSettings={{
                autoUpdateInput: false,
                timePicker: false,
                locale: {
                  format: 'M/DD hh:mm A',
                },
                drops: 'auto',
                parentEl: '#scheduledTime',
              }}
              startDate={moment(formik.values.startDate)}
              endDate={moment(formik.values.endDate)}
              isInvalid={formik.touched.startDate && !!formik.errors.startDate}
              onCancel={(event, picker) => {
                onResetDateFilter(event, picker);
              }}
              onApply={(event, picker) => {
                onDateFilterChange(event, picker);
              }}>
              <Col style={{ marginLeft: '-16px' }}>
                <Image
                  style={{
                    float: 'right',
                    margin: '0px 0px -22px 0px',
                    padding: '0px 3px 0px 0px',
                    right: '24px',
                    top: '6px',
                    position: 'absolute',
                  }}
                  src={CAL}
                />
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  style={{ fontSize: '13px' }}
                  value={formik.values.scheduledTime ? formik.values.scheduledTime : null}
                />
              </Col>
            </DateRangePicker>
          </Col>
        </Row>
        <Row style={{ marginBottom: '15px' }}>
          <Col>
            <Card className="KamSellerCard bg-grey h-60">
              <Card.Body style={{ backgroundColor: '#fff' }}>
                <Row>
                  <Col md={4}>
                    <Image
                      src={RR1}
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '2px 10px 0 0',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <span style={{ color: '#40a1bc', fontWeight: 'bold' }}>
                        {openingBalance?.toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        })}
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: '12px' }}> Opening Balance </span>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="KamSellerCard bg-grey h-60">
              <Card.Body style={{ backgroundColor: '#fff' }}>
                <Row>
                  <Col md={4}>
                    <Image
                      src={RR2}
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '2px 10px 0 0',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <span style={{ color: '#e79a00', fontWeight: 'bold' }}>
                        {totalSales?.toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        })}
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: '12px' }}> Total Sales </span>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="KamSellerCard bg-grey h-60">
              <Card.Body style={{ backgroundColor: '#fff' }}>
                <Row>
                  <Col md={4}>
                    <Image
                      src={RR3}
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '2px 10px 0 0',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <span style={{ color: '#6e6bd0', fontWeight: 'bold' }}>
                        {totalRecepits?.toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        })}
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: '12px' }}> Total Receipts </span>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="KamSellerCard bg-grey h-60">
              <Card.Body style={{ backgroundColor: '#fff' }}>
                <Row>
                  <Col md={4}>
                    <Image
                      src={RR4}
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '2px 10px 0 0',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <span style={{ color: '#e86949', fontWeight: 'bold' }}>
                        {totalAdjustments?.toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        })}
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: '12px' }}> Total Adjustments </span>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="KamSellerCard bg-grey h-60">
              <Card.Body style={{ backgroundColor: '#fff' }}>
                <Row>
                  <Col md={4}>
                    <Image
                      src={RR5}
                      style={{
                        width: '30px',
                        height: '30px',
                        margin: '2px 10px 0 0',
                        objectFit: 'contain',
                      }}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <span style={{ color: '#359c68', fontWeight: 'bold' }}>
                        {closingBalance?.toLocaleString('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        })}
                      </span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: '12px' }}> Total Receivables </span>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <RecyclerReceivablesTableNew
            loading={loading}
            refresh={refresh}
            setRefresh={setRefresh}
            receivalbesList={recyclerReceivablesList}
            setReceivalbesList={setRecyclerReceivablesList}
            recyclerList={recyclerList}
            receivablesData={receivablesData}
            setReceivablesData={setReceivablesData}
            totalCount={totalCount}
            onFilterChange={onFilterChange}
            fromDate={fromDate}
            toDate={toDate}
          />
          {/* <ManageRecyclerList
            refresh={refresh}
            setRefresh={setRefresh}
            loading={loading}
            recyclerData={recyclerList}
            totalCount={totalCount}
            onFilterChange={onFilterChange}
            deleteRecycleHandler={null}
          /> */}
        </div>
      </div>
    </PageContainer>
  );
};

RecyclerReceivablesPage.propTypes = {};

export default RecyclerReceivablesPage;
