import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  useBlockLayout,
  useFilters,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import * as _ from 'lodash';
import { find, flatMap, includes, map } from 'lodash';

import { Link } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import EmptyIcon from '../../assets/img/item-empty.png';
import { Content, Loader, PaginationComponent } from '../../shared/components';
import {
  CustomDateRangeFilter,
  InputColumnFilter,
  MultiSelectItemFilterInRecyclers,
  SelectCityCoulmnFilter,
} from '../../shared/components/FilterComponents';
import { Address, Items, OverlayCell } from '../../shared/components/GenericComponent';
import {
  convertToTableFilters,
  debounce,
  parseTableFilters,
  StringWithCommaSeparatedPayload,
  toLocalDate,
  toNextDate,
} from '../../shared/utils/Helper';

import { ReactComponent as IconFirstOrder } from '../../assets/img/first-order.svg';
import { useAsyncEffect, useCatalog } from '../../hooks';
import useStakeholders from '../../hooks/useStakeholders';
import { PAGE_CONFIG, RECYCLER_SUPER_ROLE } from '../../shared/constants/Constants';
import { ALL_MODULES } from '../../shared/constants/Modules';
import { ALL_PERMISSIONS } from '../../shared/constants/Permissions';
import HasRolePermission from '../../shared/HasRolePermission';
import { getSortBy } from '../../shared/utils/filters.utils';
import { CommaArrayParam } from '../../shared/utils/queryParams.utils';
import './ManageRecyclerList.scss';

let selectedCategory = '';
const ManageRecyclerList = ({
  recyclerData,
  loading,
  refresh,
  setRefresh,
  onFilterChange,
  totalCount,
  selectKey,
}) => {
  const { materialTypes = [], itemsFlat } = useCatalog();
  const { currentAccount } = useStakeholders();

  const queryParamsConfig = {
    page: withDefault(NumberParam, undefined),
    size: withDefault(NumberParam, PAGE_CONFIG.size),
    sort: withDefault(StringParam, 'id,desc'),
    businessName: withDefault(StringParam, undefined),
    mobile: withDefault(NumberParam, undefined),
    itemIds: withDefault(StringParam, undefined),
    regionId: withDefault(NumberParam, undefined),
    pointOfContact: withDefault(StringParam, undefined),
  };

  useEffect(() => {
    const splitAccountCategories = currentAccount?.category?.split(',');
    const includedAll = includes(splitAccountCategories, 'All');
    const selectedCategoryArray = map(splitAccountCategories, (eachCategoryOption) => {
      return find(materialTypes, (option) => option?.name === eachCategoryOption);
    });
    selectedCategory = includedAll ? '' : StringWithCommaSeparatedPayload(selectedCategoryArray);
  }, [materialTypes, currentAccount]);

  const headersParams = {
    itemIds: '',
    materialId: selectedCategory || '',
  };
  const [headersFilter, setHeadersFilters] = useState(headersParams);
  const [filters, setFilters] = useQueryParams(queryParamsConfig);

  const abortControllerRef = useRef(new AbortController());

  const { kamUsers } = useStakeholders();

  useEffect(() => {
    return () => {
      if (loading) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useAsyncEffect(async () => {
    // set default filter to table
    setAllFilters(convertToTableFilters(filters));
  }, []);

  /**
   * Effect on filter change
   */

  React.useEffect(() => {
    if (selectKey === 'RECYCLER') {
      applyFilters(filters, headersFilter);
    }
  }, [filters, headersFilter, selectKey]);

  useEffect(() => {
    if (refresh && selectKey === 'RECYCLER') {
      setAllFilters([]);
      applyFilters(filters);
      setRefresh(false);
    }
  }, [refresh]);
  /**
   *
   * send filter to server
   * @type {function(...[*]=): void}
   */
  const applyFilters = React.useCallback(
    debounce((newFilters, newHeadersFilter) => {
      const latestFilters = { ...newFilters };
      onFilterChange(latestFilters, newHeadersFilter);
    }, 100),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Business Name',
        accessor: 'businessName',
        disableSortBy: true,
        Filter: InputColumnFilter,
        width: 350,
        Cell: (cellInfo) => {
          const { isProcessor, id, preferredCategoryId } = cellInfo.row.original;

          return (
            <>
              {isProcessor && (
                <Col md="2">
                  <div>
                    {isProcessor ? (
                      <OverlayCell hideOverlay={!isProcessor} overlay={<div>Co-Processor</div>}>
                        <div>
                          <IconFirstOrder />
                        </div>
                      </OverlayCell>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              )}
              <Col md="10" className="pl-1">
                <div style={{ width: '100%', textAlign: 'left' }}>
                  <Link
                    to={`/recyclers/${id}/edit`}
                    style={{
                      textDecoration: 'underline',
                      color: '#212529',
                      backgroundColor: 'white',
                    }}>
                    {cellInfo?.value?.toUpperCase()}
                  </Link>
                </div>
              </Col>
            </>
          );
        },
      },
      {
        Header: 'Contact Person',
        disableSortBy: true,
        Filter: false,
        accessor: 'user',
        width: 250,
        Cell: (cellInfo) => {
          const rowData = cellInfo.row.original;
          return (
            <div style={{ width: '100%', textAlign: 'left' }} className="p-2">
              {_.filter(rowData?.user, (e) => {
                return e.department === RECYCLER_SUPER_ROLE;
              }).length > 0
                ? _.sumBy(
                    _.filter(rowData?.user, (e) => {
                      return e.department === RECYCLER_SUPER_ROLE;
                    }),
                    'name'
                  )
                : '-'}
            </div>
          );
        },
      },
      {
        Header: 'Registered Mobile',
        canSort: false,
        disableSortBy: true,
        accessor: 'mobile',
        width: 250,
        Filter: InputColumnFilter,
        Cell: (cell) => {
          const rowData = cell.row.original;

          const data = rowData?.mobileNumber || '-';

          return (
            <div style={{ display: 'flex', width: '100%' }} className="p-2">
              <div style={{ textAlign: 'left', width: '90%' }}>{data || 'NA'}</div>
            </div>
          );
        },
      },
      {
        Header: 'Items',
        accessor: 'itemIds',
        disableSortBy: true,
        width: 300,
        Filter: MultiSelectItemFilterInRecyclers,
        Cell: (cell) => {
          const rowData = cell.row.original;
          const items = flatMap(rowData?.itemDetails);

          return (
            <div className="p-1" style={{ textAlign: 'left', width: '100%' }}>
              <Items value={items} />
            </div>
          );
        },
      },
      {
        Header: 'Location',
        accessor: 'regionId',
        disableSortBy: true,
        width: 300,
        Filter: SelectCityCoulmnFilter,
        Cell: (cell) => {
          const rowData = cell?.row?.original;
          const address = rowData?.address?.find((addr) => addr?.isDefault === true);
          return (
            <div className="p-1" style={{ textAlign: 'left', width: '100%' }}>
              <Address short value={address} />
            </div>
          );
        },
      },
      {
        Header: 'SPOC',
        accessor: 'pointOfContact',
        disableSortBy: true,
        Filter: InputColumnFilter,
        width: 300,
        Cell: (cellInfo) => {
          const rowData = cellInfo.row.original;

          return (
            <div style={{ display: 'flex', width: '100%' }} className="p-2">
              <div style={{ textAlign: 'left', width: '90%' }}>
                {kamUsers[cellInfo?.value]?.firstName || '-'}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        sticky: 'right',
        width: 200,
        canSort: false,
        Cell: (cell) => {
          const rowData = cell.row.original;
          const overLayPopover = (
            <Popover id={`popover-positioned-${'left'}`}>
              <Popover.Content>
                <ListGroup variant="flush" defaultActiveKey="#link1">
                  <ListGroup.Item as={Link} to={`/recyclers/${rowData.id}/edit`} action>
                    Edit
                  </ListGroup.Item>
                </ListGroup>
              </Popover.Content>
            </Popover>
          );

          return (
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="p-2">
              <HasRolePermission
                moduleName={ALL_MODULES?.BUYERS}
                permissionName={[ALL_PERMISSIONS?.EDIT]}>
                <OverlayTrigger
                  trigger="click"
                  key="left"
                  placement="left"
                  rootClose
                  overlay={overLayPopover}>
                  <FaEllipsisV className="float-right" />
                </OverlayTrigger>
              </HasRolePermission>
            </div>
          );
        },
      },
    ],
    [recyclerData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, filters: tableFilters, sortBy },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        pageSize: PAGE_CONFIG.size,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / PAGE_CONFIG.size),
      data: recyclerData || [],
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    useBlockLayout,
    useResizeColumns,
    useRowSelect,
    useSticky
  );

  useEffect(() => {
    const _filters = parseTableFilters(tableFilters);
    const sort = getSortBy(sortBy);
    const _headerFilters = { ...headersParams };
    Object.keys(_filters).forEach((key) => {
      if (key in headersParams) {
        _headerFilters[key] = _filters[key];
        delete _filters[key];
      }
    });
    const updatedFilters = {
      ..._filters,
      size: filters.size,
      page: 0,
      sort,
    };
    setHeadersFilters((prev) => ({ ...prev, ..._headerFilters }));
    setFilters(updatedFilters, 'replace');
  }, [tableFilters, sortBy]);

  useEffect(() => {
    setFilters({ ...filters, size: filters?.size, page: pageIndex }, 'replace');
  }, [pageIndex]);

  return (
    <React.Suspense fallback={<Loader />}>
      <div {...getTableProps()} className="recykleritemList table sticky">
        <div className="header">
          {headerGroups.map((headerGroup, rowNum) => (
            <>
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column, idx) => (
                  <div
                    key={idx}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th border-right">
                    <div className="d-flex flex-row align-items-center">
                      {column.render('Header')}
                    </div>
                  </div>
                ))}
              </div>
              <div {...headerGroup.getHeaderGroupProps()} className="tr filter-row">
                {headerGroup.headers.map((column, k) => (
                  <div
                    {...column.getHeaderProps({ style: { width: column.width } })}
                    className="th">
                    {column.Filter ? column.render('Filter') : null}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>

        <div {...getTableBodyProps()} style={{ height: '52vh' }} className="body">
          <Content
            loading={loading}
            emptyIcon={EmptyIcon}
            hasData={!!rows.length}
            isFiltered={filters?.length > 0}
            emptyResultMessage="There are no Buyers for the selected criteria"
            emptyMessage="There are no Buyers added">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className="td align-items-center">
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Content>
        </div>
      </div>
      <PaginationComponent
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        loading={loading && recyclerData?.length}
        gotoPage={gotoPage}
        currentPage={pageIndex}
        numberOfPages={pageOptions.length}
        totalCount={totalCount}
      />
    </React.Suspense>
  );
};

export default React.memo(ManageRecyclerList);
