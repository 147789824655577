import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numberWithCommas, formatNumberToIndianSystem } from '../utils/Helper';

const Quantity = (props) => {
  const { value = 0, prefix, className, postfix, styles, ...rest } = props;
  return (
    <>
      <span
        className={classNames('quantity', className)}
        style={{ display: 'block', ...styles }}
        {...rest}
        dangerouslySetInnerHTML={{
          __html: `${prefix ?? ''}${
            Number.isInteger(value)
              ? formatNumberToIndianSystem(value, 0)
              : formatNumberToIndianSystem(value, 3)
          }  ${postfix ?? ''}`,
        }}
      />
    </>
  );
};

Quantity.propTypes = {
  value: PropTypes.any,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
};

export default memo(Quantity);
