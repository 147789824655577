import moment from 'moment';
import { add, isEmpty, sortBy, map, sum } from 'lodash';
import { ORDER_STATUS, PAYMENT_TERM_EVENT } from '../constants/Constants';
import {
  toServerDate,
  getDateAdd,
  toLocalDate,
  toRupees,
  qtyValue,
  toLocalDateTime,
} from './Helper';

/* eslint-disable no-nested-ternary */
export default function getAggregatorPaymentDate(order, event) {
  const date = new Date();
  const paymentTerm = order?.sellerDTO?.paymentTermConfigDTO?.[0];
  const latestLogEvent = sortBy(order?.logs, ['id'])?.reverse()?.[0];
  return paymentTerm?.paymentDate !== null
    ? paymentTerm?.paymentDate
    : paymentTerm?.paymentTermEvents === event
    ? toServerDate(getDateAdd(date, paymentTerm?.daysFromEvent))
    : null;
}

export const caluculatePaymentDate = (res, order) => {
  const paymentTermDate = order?.logs?.find(
    (item) => item?.toStatus === res?.paymentsEvents?.[0]?.paymentTermEvents
  )?.changedDate;

  return toServerDate(
    getDateAdd(
      paymentTermDate,
      res?.daysFromEvent || res?.paymentsEvents?.[0]?.paymentTermNumberOfDays
    )
  );
};

export const sortByPaymentTermEvent = (arr) => {
  return arr?.sort(
    (a, b) =>
      PAYMENT_TERM_EVENT?.find((i) => i?.event === a?.paymentTermEvents).id -
      PAYMENT_TERM_EVENT?.find((i) => i?.event === b?.paymentTermEvents).id
  );
};

export function daysFromEvent(paymentTerm) {
  return paymentTerm?.noofinstanttxn > paymentTerm?.totalSaleOrdersTillDate
    ? [0, 'MATERIAL_LOADED']
    : null;
}

export function setPaymentTermEvent(event) {
  return PAYMENT_TERM_EVENT?.find((e) => e?.event === event)?.name;
}

export function setPaymentTermsLogisticLogs(response) {
  return response?.seller?.paymentTermConfigDTO?.paymentTermEvents
    ? `${setPaymentTermEvent(response?.seller?.paymentTermConfigDTO?.paymentTermEvents)} +
         ${response?.seller?.paymentTermConfigDTO?.daysFromEvent} Days ${
        response?.aggrPaymentTerms?.length > 0
          ? `, ${toLocalDate(response?.aggrPaymentTerms?.[0]?.paymentDate)}`
          : ''
      }`
    : toLocalDate(response?.seller?.paymentTermConfigDTO?.paymentDate);
}

/**
 *
 * @param {*} x : return the range of date.
 */
export function getDateRange(x) {
  const date = new Date();
  date.setDate(date.getDate() + x);
  return [toServerDate(moment(date)), toServerDate(moment(new Date()))];
}

export function dateFormatTracking(date) {
  return date?.replace('[Asia/Kolkata]', '')?.replace('[Asia/Calcutta]', '');
}

export function removeFromString(arr, str) {
  const regex = new RegExp(`\\b${arr.join('|')}\\b`, 'gi');
  return str.replace(regex, '');
}

export function trimChar(str, char) {
  return str
    ?.split(char)
    ?.filter((s) => !isEmpty(s.trim()))
    ?.join(char);
}

export async function extractAddressGResponse(geoCodeResponse) {
  const addr = {
    zipCode: '',
    lat: null,
    lng: null,
    streetAddress: '',
  };

  addr.lat = geoCodeResponse.results[0]?.geometry.location.lat;
  addr.lng = geoCodeResponse.results[0]?.geometry.location.lng;
  geoCodeResponse.results[0].address_components.forEach((item) => {
    if (item.types.includes('postal_code')) {
      addr.zipCode = item.long_name;
    }
    if (item.types.includes('locality')) {
      addr.city = item.long_name;
    }
    if (item.types.includes('administrative_area_level_1')) {
      addr.state = item.long_name;
    }
    if (item.types.includes('country')) {
      addr.country = item.long_name;
    }
  });

  const toRemove = [addr.city, addr.state, addr.zipCode, addr.country];
  addr.streetAddress = trimChar(
    removeFromString(toRemove, geoCodeResponse.results[0]?.formatted_address),
    ','
  );
  addr.createdDate = toLocalDate(new Date().toISOString(), 'DD/MM/YY, hh:mm A');
  return addr;
}

export function parseToRequiredDecimal(value = 0, decimal = 0) {
  if (typeof value !== 'number') {
    return 'NA';
  }
  if (Number.isInteger(value)) {
    return value;
  }
  return parseFloat(value)?.toFixed(decimal);
}

export const convertToDateHandler = (year, month, day, format = 'YYYY-MM-DD') => {
  return moment(`${year} ${month} ${day}`, 'YYYY MMMM DD').format(format);
};

export const aggregatorPaymentTermsJson = (order, status) => {
  const payments = [];
  let paymentEvents = [];
  let updatedDate = null;
  const getTotalAmountForAggregators = (items, shipmentDate) => {
    // const weighbridgeDetails = items?.every((item) => item?.weighbridgeDetails?.length === 0);
    // if (weighbridgeDetails) {
    //   return map(
    //     items,
    //     ({ price, incentivePrice, qty, oldQty }) =>
    //       toRupees(price + incentivePrice) * (oldQty || qty)
    //   );
    // }

    if (shipmentDate) {
      return map(
        items,
        ({ sellerPrice, dispatchedQuantity, quantity }) =>
          sellerPrice *
          qtyValue(shipmentDate, dispatchedQuantity > 0 ? dispatchedQuantity : quantity)
      );
    }
    return 0;
  };

  const itemPriceAndQty = getTotalAmountForAggregators(order?.mpShipmentItems, order?.createdDate);

  const totalOrderAmount = sum(itemPriceAndQty)?.toFixed(2);
  const isPaymentEvent = order?.sellerDTO?.sellerStatus?.paymentTermConfigs;
  const globalSuggestedDate = (status, event) => {
    let globalSuggestedDate = null;
    const isEventPresentDate =
      status === (event?.paymentTermEvents || event?.paymentTermEvent)
        ? new Date()
        : sortBy(order?.logs, ['id'])
            ?.reverse()
            ?.find((log) => log?.toStatus === (event?.paymentTermEvents || event?.paymentTermEvent))
            ?.changedDate;
    isPaymentEvent?.forEach((evt) => {
      if (evt?.paymentTermEvents === (event?.paymentTermEvents || event?.paymentTermEvent)) {
        globalSuggestedDate = isEventPresentDate
          ? toServerDate(
              getDateAdd(isEventPresentDate, evt?.paymentTermNumberOfDays || evt?.daysFromEvent)
            )
          : 'NA';
      }
    });
    return globalSuggestedDate;
  };

  const updatedSuggestedDate = (status, event) => {
    const isEventPresentDate =
      status === (event?.paymentTermEvents || event?.paymentTermEvent)
        ? new Date()
        : sortBy(order?.logs, ['id'])
            ?.reverse()
            ?.find((log) => log?.toStatus === (event?.paymentTermEvents || event?.paymentTermEvent))
            ?.changedDate;

    updatedDate = isEventPresentDate
      ? toServerDate(
          getDateAdd(isEventPresentDate, event?.paymentTermNumberOfDays || event?.daysFromEvent)
        )
      : 'NA';

    return updatedDate;
  };

  order?.sellerPaymentTerms?.forEach((rs) => {
    const obj = {};
    obj.paymentPercentage = rs?.paymentPercentage;
    obj.paymentDate = rs?.paymentDate;
    paymentEvents = rs?.paymentEventsJson ? JSON.parse(rs?.paymentEventsJson) : [];
    if (paymentEvents?.length > 0) {
      paymentEvents?.forEach((event) => {
        event.percentageAmount = event?.percentageAmount
          ? event?.percentageAmount
          : ((totalOrderAmount / 100) * event?.amountPercentage).toFixed(2);
        event.globalSuggestedDate =
          event?.globalSuggestedDate !== 'NA' && event?.globalSuggestedDate !== undefined
            ? event?.globalSuggestedDate
            : globalSuggestedDate(status, event);
        event.updatedSuggestedDate =
          event?.updatedSuggestedDate !== 'NA' && event?.updatedSuggestedDate !== undefined
            ? event?.updatedSuggestedDate
            : updatedSuggestedDate(status, event);
      });
    }
    obj.paymentDate = rs.paymentDate;
    obj.paymentTerm = 'STANDARD';
    obj.paymentsEvents = paymentEvents;
    payments.push(obj);
  });
  return JSON.stringify(paymentEvents);
};
