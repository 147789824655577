/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { ButtonToolbar, Button, Col, Row } from 'react-bootstrap';
import { groupBy, pick, map, keyBy, unionBy, flatMap } from 'lodash';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { FaRedoAlt } from '@react-icons/all-files/fa/FaRedoAlt';
import { Loader, PageContainer } from '../../shared/components';
import RequisitionList from '../../features/Requisitions/RequisitionList';
import UpdateRequisition from '../../features/Requisitions/UpdateRequisition';
import { useRequisition } from '../../hooks/hooks';
import { useAsyncEffect, useRegion, useStakeholders } from '../../hooks';
import { hasError } from '../../shared/utils/Helper';
import QuotesListPage from './QuotesListPage';

const QuotesList = () => {
  const loadingold = false;
  const [refresh, setRefresh] = useState(false);
  const abortControllerRef = useRef(new AbortController());

  const [
    {
      errorQuote,
      loadQuote,
      valueQuote: { data: quotesData = [], totalCount },
    },
    { doFetchQuotes },
  ] = useRequisition();

  const onFilterChange = async (params) => {
    if (loadQuote) {
      abortControllerRef.current.abort();
    }
    await doFetchQuotes(params);
  };

  return (
    <PageContainer>
      <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        <Row>
          <Col>
            <h4>Quotes</h4>
          </Col>
        </Row>
        <QuotesListPage
          quotesData={quotesData?.length > 0 ? _.orderBy(quotesData, 'createdOn', 'desc') : []}
          totalCount={totalCount}
          loading={loadQuote}
          onFilterChange={onFilterChange}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </PageContainer>
  );
};

export default QuotesList;
