const ErrorCode = {
  AUTHORIZATION_FAILURE: 'AUTHORIZATION_FAILURE',
  PAYMENT_DONE: 'Payment done successfully.',
  PAYMENT_TERM_APPROVAL:
    'Please complete the payment term approval to proceed for admin verification.',
  FAILED_DOCUMENT: 'Failed to upload the document.',
  PAYMENT_RECEIVED: 'Payment received successfully.',
  JOURNAL_UPLOAD: 'Journal voucher uploaded successfully.',
  PAYMENT_INFO: 'Payment info saved successfully.',
  PINCODE_DIGIT: 'Please enter a 6-digit pincode.',
  DUPLICATES_INFO: 'Duplicates are not allowed.',
  REMAINDER_SENT: 'Reminder sent successfully.',
  SELLER_DETAILS: 'Seller details updated successfully.',
  UPDATE_SPOC: 'Successfully updated spoc.',
  UPDATE_RSM: 'Successfully updated RSM.',
  UNABLE_UPDATE_SPOC: 'Unable to update spoc.',
  UNABLE_UPDATE_RSM: 'Unable to update RSM.',
  PINCODE_NOT_SERVICEABLE: 'Pincode is not serviceable.',
  VALUE_UPDATED: 'Value has been updated successfully.',
  UPLOADED_DATA: 'Uploaded successfully, data will be updated shortly.',
  INITIATED: 'Initiated successfully.',
  REJECT_NOTE: 'Please enter the Reject Note.',
  PICKUP_SCHEDULE: 'Pickup scheduled updated successfully.',
  BUYER_DEACTIVATED: 'Buyer has been deactivated, Please contact Business Admin.',
  PICKUP_SCHEDULE_DATE: 'Please enter the pickup schedule date.',
  PICKUP_SCHEDULE_UPDATE: 'Pickup scheduled updated successfully.',
  LISTING_INTO_SALE_ORDER: 'Listing successfully split into sale order.',
  COMING_SOON: 'Coming soon with this feature.Thank you for support.',
  ITEM_QUANTITY: `Either the item quantity is 0 or item quantity 
                  is greater than item actual quantity of the listing or Primary Item not present.`,
  CORRECT_LOCATION: 'Please enter a correct location.',
  TRACKING_TRACES: 'Tracking traces added successfully.',
  ERROR_VALUE: 'Error while updating the value.',
  LISTING_PRICE: 'Listing price updated successfully.',
  PICKUP_SCHEDULE_TIME: 'Pickup scheduled time required.',
  PURCHASE_ORDER_APPROVED: 'Purchase Order approved successfully.',
  ORDER_SENT: 'Order sent for approval successfully.',
  ERROR_PRICE: 'Error while updating the price !!',
  UNABLE_UPDATE_SP: 'Unable to update the seller price.',
  UNABLE_FETCH_LOGS: 'Unable to fetch the logs.Please try again.',
  UPDATED_SP: 'Seller Price updated successfully.',
  PAYMENT_RECORDED: 'Payment recorded successfully.',
  LISTING_ADDED_REMOVED: 'Listing Added/Removed successfully.',
  ENTER_COMMENT_REJECTION: 'Please enter the comment for rejecting.',
  NO_DOCUMENT_FOUND: 'No document found for this bank details.',
  BUYER_ACTIVATED: 'The Buyer is activated successfully.',
  SELLER_ACTIVATED: 'Seller activated successfully.',
  SELLER_DEACTIVATED: 'Seller deactivated successfully.',
  POC_DETAILS: 'POC details updated successfully.',
  BUYER_DEACTIVATED_SUCCESS: 'The Buyer is deactivated successfully.',
  DEFAULT_ADDRESS: 'Default address cannot be deleted.',
  DATA_SAVED: 'Data saved successfully.',
  OOPS_MESSAGE: 'Oops... Looks like something went wrong, Please try again.',
  BUYER_PAYMENT_TERM_CLONED: 'Buyer PaymentTerm cloned successfully.',
  BUYER_PAYMENT_TERM_UPDATED: 'Buyer PaymentTerm updated successfully.',
  REQUISITION_CREATED: 'Sale Order created successfully.',
  REQUISITION_UPDATED: 'Sale Order updated successfully.',
  QUOTE_PRICE_UPDATED: 'Quote Price updated successfully.',
  QUOTE_QUANTITY_ERROR: 'Quote quantity is greater than Requisition pending quantity.',
  REMOVE_INCORRECT_DOCUMENTS:
    'Please remove all the incorrect documents and save for accounts verification !!',
  REASONS_DOCUMENT_TYPES: 'Reasons not defined for few document types !!',
  INCORRECT_DOCUMENTS_SAVED: 'Incorrect documents saved successfully.',
  NEW_ITEMS_ADDED: 'New item added successfully.',
  LOGISTIC_COST_UPDATED: 'Logistic cost updated successfully.',
  WEIGHBRIDGE_INFO_ADDED: 'WeighBridge info added successfully.',
  TRANSPORTER_DELETED: 'Transporter was deleted successfully.',
  TRANSPORTER_CREATED: 'Transporter created successfully.',
  CHANGES_SAVED: 'Changes saved successfully.',
  FAILED_ZONED_DATA: 'Failed to updated zone data.',
  FAILED_LOAD_DATA: 'Failed to load data',
  COMPLETE_PAYMENT_TERM:
    'Please complete the payment term approval to proceed for admin verification',
  QC_DONE: 'QC done successfully.',
  KAM_CHANGED: 'Kam changed successfully.',
  NOTE_CHANGED: 'Notes added successfully.',
  PAYMENT_DELETED: 'Payment deleted successfully.',
  ADMIN_VERIFICATION_DONE: 'Admin verification done successfully.',
  BUSINESS_HEAD_APPROVED: 'Order approved successfully.',
  BUSINESS_HEAD_REJECTED: 'Business Head rejected the order successfully.',
  EMAIL_SENT: 'Email sent successfully.',
  ACCOUNT_TEAM_APPROVED: 'Accounts team approved the order successfully.',
  ACCOUNT_TEAM_REJECTED: 'Accounts team rejected the order successfully.',
  EPR_DONE: 'Epr done successfully.',
  PAYMENT_UPDATED: 'Payment updated successfully.',
  PICKUP_DETAILS_UPDATED: 'Pickup details updated successfully.',
  MATERIAL_LOADED: 'Material loaded successfully.',
  DISPATCH_DETAILS_UPDATED: 'Updated dispatched details.',
  MARK_DISPATCHED: 'Mark as dispatched successfully.',
  DELIVERY_DETAILS_UPDATED: 'Updated delivery details',
  MARK_DELIVERY: 'Mark as delivered successfully.',
  MARK_QC_DONE: 'Mark as Qc done.',
  VALUES_SAVED: 'The values saved successfully.',
  PASSWORD_RESET: 'Password has been reset successfully.',
  USER_DETAILS_CHANGED: 'User details changed successfully.',
  SELLER_USER_ADDED: 'Seller user added successfully.',
  SELLER_USER_EDITED: 'Seller user edited successfully.',
  SELLER_USER_DELETED: 'Seller user deleted successfully.',
  BUYER_USER_ADDED: 'Buyer user added successfully.',
  BUYER_USER_EDITED: 'Buyer user edited successfully.',
  BUYER_USER_DELETED: 'Buyer user deleted successfully.',
  BUYER_DELETED: 'Buyer deleted successfully.',
  BUYER_PAYMENT_TERM_ADDED: 'Buyer PaymentTerm Added Successfully.',
  ORDER_APPROVED: 'Shipment approved successfully.',
  ORDER_SENT_AGGREGATOR: 'Order sent for aggregator approval successfully.',
  NOTIFICATION_AGGREGATOR_UPLOAD: 'Notification sent to aggregator to upload more images.',
  DOCUMENT_DELETED: 'Document deleted successfully.',
  QUANTITY_REJECTED: 'Quantity Rejected successfully.',
  PAYMENT_TERMS_DEFINED: 'Payment Terms were defined successfully.',
  SELLER_INVOICE_CONFIRMED: 'Seller Invoice confirmed successfully.',
  VERIFICATION: 'Verification successfully.',
  PICKED_VERIFIED_QUANTITY:
    'Looks like there is a difference in Picked Quantity and Verified Quantity, Please check once.',
  TRANSPORTER_INVOICE_SAVED: 'Transporter invoice details saved successfully.',
  TRANSPORTER_INVOICE_APPROVED: 'Transporter invoice details approved successfully.',
  UPLOAD_LR_COPY: 'Please upload LR Copy to generate the Transporter Invoice Details.',
  TRANSPORTER_INVOICE_REJECTED: 'Transporter invoice details rejected successfully.',
  ETA_UPDATED: 'ETA updated successfully.',
  ASSESSMENT_SAVED: 'Assessment saved successfully.',
  ASSESSMENT_DRAFT: 'Assessment saved as draft.',
  ASSESSMENT_MARKED: 'Assessment has been marked.',
  ASSESSMENT_COMPLETED: 'Assessment has been completed.',
  ORDER_RE_ASSIGN: 'Order has been re-assigned to new Buyer successfully.',
  AGGREGATOR_APPROVAL: `Sent for aggregator's approval. Status of the
        order will be pending approval till aggregator's review & confirms the order.`,
  COMMENTS_ADDED: 'Comments added successfully.',
  NOTE_ADDED: 'Note added successfully.',
  SMS_SENT: 'SMS sent successfully.',
  TRIP_SUBSCRIBED: 'Trip successfully subscribed for tracking.',
  SOMETHING_WRONG: 'Something went wrong please try again.',
  TRIP_UNSUBSCRIBED: 'Trip successfully unsubscribed.',
  TRACKING_NOT_AVAILABLE: 'Tracking location not available.',
  SUBSCRIPTION_NOT_PRESENT: 'Subscription details not present due to incorrect mobile number.',
  ORDER_REJECTED: 'Shipment rejected successfully.',
  RECEIVED_QUANTITY: 'Please enter the received qty to reject shipment.',
  NOTE_CREATED: 'Note created in Zoho.',
  ENTER_LINE_ITEMS: 'Please enter the line Items!!',
  BUYER_QUALITY_PARAMS: 'Please enter the Buyer Quality Params.',
  TRANSPORTER_TRUCK_DETAILS: 'Transporter and Truck details updated successfully.',
  TRANSPORTER_INVOICE_CONFIRMED: 'Invoice generated successfully.',
  BUYER_INVOICE_CONFIRMED: 'Buyer Invoice confirmed successfully.',
  TRACKING_LOCATION: 'Tracking Location not available yet..',
  SELECT_ROLE: 'Please select the Role.',
  SPOC_CREATION: 'Please select the regional state manager for spoc creation!!',
  UPDATE_PASSWORD: 'Updated password successfully.Please re-login to continue.',
  UPDATE_ACCOUNT_DETAILS: 'Updated account details successfully.',
  UPDATED_DISPATCH_DETAILS: 'Updated dispatched details',
  SENT_REMINDER: 'Sent reminder successfully.',
  NEW_KAM_ASSIGNED: 'New KAM assigned to this aggregator successfully.',
  UPDATED_AGGREGATOR: 'Updated aggregator successfully.',
  UPDATED_ADDRESS: 'Updated address successfully.',
  ADDED_ADDRESS: 'Added address successfully',
  UPDATED_SUCCESSFULLY: 'Updated successfully.',
  PAYMENT_TERM_UNSUCCESS: 'Payment Term unsuccessful.',
  PAYMENT_TERM_EDITED: 'Payment Term has been edited successfully.',
  PAYMENT_TERM_ADDED: 'Payment Term has been added successfully.',
  CANNOT_ADD_EDIT_ADDRESS:
    'Cannot Add/Edit the entry for all states, All cities, All categories and All material !!',
  PAYMENT_TERM_UPDATED: 'Payment Terms updated successfully.',
  SELLER_PAYMENT_ADDED: 'Seller payment added successfully.',
  ENTER_PAYMENT_TERM: 'Please enter the payment term event.',
  ENTER_PAYMENT_TERM_DAYS: 'Please enter the payment event no of days.',
  ENTER_PAYMENT_DATE: 'Please enter the payment date.',
  COMMENT: 'Please enter the comment.',
  USER_TYPE_UPDATED: 'User type updated successfully.',
  USER_TYPE_CREATED: 'User type created successfully.',
  UPDATED_SUCCESS: 'Updated successfully.',
  LISTING_VISIBILITY: 'Listing visibility updated successfully.',
  DATA_NOT_FOUND: 'Data not found.',
  CAMPAIGN_CREATED_DRAFT: 'Campaign created successfully with status Draft.',
  CAMPAIGN_CREATED: 'Campaign created successfully.',
  MANDATORY_FIELDS: 'Enter all mandatory fields.',
  UPDATED_CITY: 'Updated city successfully.',
  ADDED_CITY: 'Added city successfully.',
  OOPS_MESSAGE_SUPPORT:
    'Oops..! Something went wrong..! Please reach support team. marketpalce.support@recykal.com',
  UNABLE_FETCH_BUYER: 'Unable to fetch Buyer Quality Params.',
  INVALID_SALE_ORDER: 'Invalid Sale Order Status.',
  ENTITY_ADDED: 'The Entity has been successfully added.',
  ITEM_ZOHO_EXISTS: 'Item with this ZOHO ID already exists.',
  UNABLE_ADD_ENTITY: 'Unable to add the entity !!',
  QUALITY_PARAMETERS_ADDED: 'Quality Parameters added successfully.',
  UNABLE_UPDATE: 'Unable to Update Quality Parameters.',
  ITEM_STATUS_UPDATED: 'Item status updated successfully.',
  UNABLE_UPDATE_ITEM_STATUS: 'Unable to update Item Status !!!',
  MATERIAL_TYPE_ADDED: 'Material type has been added successfully.',
  UNSUCCESSFUL: 'Unsuccessful, Please try again.',
  MATERIAL_TYPE_EDITED: 'Edited the material type successfully.',
  CANNOT_DRAG_ITEM: 'Cannot drag item from one category to another category.',
  ITEM_RANK_SUCCESS: 'Item rank successfully updated.',
  UNABLE_UPDATE_ITEM_RANK: 'Unable to update the item Rank.',
  KAM_CITY_ASSIGNMENT: 'KAM city assignment change successfully updated.',
  UNABLE_FETCH_BSI: 'Unable to fetch the Buyer Seller Information for this listing.',
  LISTING_DEACTIVATED: 'Listing deactivated successfully.',
  REQUISITION_ASSIGNED_LISTING: 'Requisition assigned to Listing successfully.',
  ITEM_UPDATED: 'Item updated successfully.',
  LISTING_REJECTED: 'Listing rejected successfully.',
  ITEM_DELETED: 'Item deleted successfully.',
  LISTING_REACTIVATED: 'Listing re-activated successfully.',
  SELLER_NOT_SIGNED: 'Seller not signed the agreement, Please get it signed to proceed further.',
  BUYER_PRICE_CHANGED: 'Buyer price changed.',
  VALUES_RESET: 'Values Reset.',
  RESET_PASSWORD_LINK: 'Reset password link sent to your email id. Login with new password.',
  ITEM_ADDED_UPDATED: 'Item added/updated successfully.',
  ROUTE_DELETED: 'Route deleted successfully.',
  PURCHASE_ORDER_DELETED: 'Purchase Order deleted successfully.',
  STATUS_CHANGED: 'Status changed successfully.',
  PO_DETAILS_SAVED: 'PO details saved successfully.',
  PO_CANT_CLOSE: "You can't close this Purchase Order.",
  PO_DETAILS_REJECTED: 'PO details rejected successfully.',
  RM_QRF_DELETED: 'Successfully deleted the Rm and Qrf.',
  BUYER_CREATED: 'Buyer created successfully.',
  PICKUP_SCHEDULE_UPDATED: 'Pickup Scheduled updated successfully',
  LISTING_APPROVED: 'Listing approved successfully.',
  LOGIN_ERROR: 'Unable to login, please check your credentials',
  LOGIN_SUCCESS: 'Logged in successfully.',
  DEBIT_NOTE_MANDATORY: 'Note document is mandatory to approve note from buyer.',
  ROLE_NAME_EXISTS: 'Role Name already exists.',
  USER_NAME_ADDED:
    'New user added successfully.Registration invite link has been sent to users email id.',
  USER_NAME_UPDATED: 'User updated successfully.',
  ROLE_CLONED: 'Role cloned successfully.',
  ROLE_UPDATED: 'Role edited successfully.',
  ROLE_CREATED: 'Role created successfully.',
  CATEGORY_ADDED: 'Category added successfully.',
  MATERIAL_ADDED: 'Material added successfully.',
  ITEM_ADDED_M2: 'Item added successfully. Add Quality parameters for the Item.',
  ITEM_ADDED_M3: 'Item added successfully. Add Quality parameters to mark item as active.',
  ITEM_EDITED: 'Item edited successfully.',
  ITEM_TOGGLE_ENABLE: 'Item enabled successfully.',
  ITEM_TOGGLE_DISABLE: 'Item disabled successfully.',
  HSN_CODE_MANDATORY: 'HSN/SAC Code is mandatory. Please update in Zoho.',
  INACTIVE_USER: 'You have inactive for more than 10 minutes. Please re-login.',
  GST_COMBINATION_EXISTS: 'Combination of this month and year already exists',
  GST_DETAILS_FIELD_MISSING: 'Please fill all the information to proceed',
  EXCEEDING_CURRENT_DATE: 'Please choose the date less than the current Date.',
  ITEM_ADDED_RECYCLER: 'Items changes done successfully.',
  GSTIN_INVALID: 'GSTIN is invalid.',
  SELLER_INVOICE_DETAILS: 'Seller invoice details added successfully.',
  VEHICLE_DETAILS: 'Vehicle details added successfully',
  APPROVE_DOCUMENTS: 'Documents approved successfully',
  REJECT_DOCUMENTS: 'Documents rejected successfully',
  SELLER_INVOICE_DOCUMENTS: 'Seller invoice generated successfully',
  RECYCLER_INVOICE_DOCUMENTS: 'Sales invoice generated successfully',
  TRIP_SUCCESS: 'Trip Unsubscribed successfully.',
  EWAYBILL_FETCHED_SUCCESSFULLY: 'E-WAY Bill details fetched Successfully',
  MOVE_TO_ITAD: 'Listing moved to ITAD successfully.',
  LISTING_CREATION: 'Listing created successfully.',
  APP_LEAD: 'Lead added successfully.',
  ADD_ATTRIBUTE: 'Added successfully.',
  EDIT_ATTRIBUTE: 'Updated successfully.',
  DELETE_ATTRIBUTE: 'Deleted successfully.',
  NO_DOC_FOUND: 'No document found',
  SUCCESS: 'Success',
  FILL_REQUIRED_FIELDS: 'Fill the required fields to proceed.',
  ADDRESS_COPIED: 'Address copied successfully.',
  ADDRESS_NOT_COPIED: 'Error while copying the address.',
  DEBIT_NOTE: 'Debit Note added successfully.',
  REFRESH_SUCCESS: 'Refreshed successfully.',
  GST_DETAILS: 'GST details added successfully.',
  SEGMENT_DETAILS: 'Segment details saved successfully',
  SEGMENT_DETAILS_UPDATE: 'Segment details updated successfully',
  SEGMENT_NAME_DUPLICATE: "Segment Name can't be Duplicate",
  SEGMENT_DELETE: "Single Segment can't be delete",
  VKYC_PENDING: 'VKYC is Pending',
  DETAILS_SAVED: 'Details saved successfully.',
  COMPLETE_ONBOARD: 'Seller onboarding is successfully completed.',
  ORDER_ON_HOLD: 'Sale order is successfully on hold',
  MANAGE_UPDATES: 'Added successfully.',
  READY_FOR_ONBOARD: 'Seller is marked as Ready for Onboarding.',
  REJECT_ONBOARD_BY_FINANCE: 'Onboarding has been rejected. Mail sent to the Business Executive.',
  FASTAG_TRACES_NOT_FOUND: 'No FASTag Traces were found.',
  SHIPMENT_CREATION: 'Shipment created successfully',
  PO_ITEMS_DETAILS: 'Purchase Order items updated succesfully',
  SHIPMENT_ITEMS_EDIT: 'Shipment items edited successfully.',
};

export default ErrorCode;
