/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Button, Col, ButtonToolbar, Card, Image } from 'react-bootstrap';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { useFormik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { FaRedoAlt } from '@react-icons/all-files/fa/FaRedoAlt';
import PageContainer from '../../hoc/PageContainer';
import {
  usePurchaseOrder,
  useRecyclers,
  useRecyclerPaymentTermsAndCreditLimit,
} from '../../hooks/hooks';
import { useAsyncEffect, useStakeholders } from '../../hooks';
import { hasError, toLocalDate, toRupees, toServerDate } from '../../shared/utils/Helper';
import DeletePoModal from '../../features/OrderManagement/PurchaseOrder/DeletePoModal';
import IndividualReceivablesTable from '../../features/RecyclerReceivables/IndividualReceivablesTable';
import { pick } from 'lodash';

const IndividualReceivables = (props) => {
  const { match } = { ...props };
  const { id } = match.params;
  const [recyclerList, setRecyclerList] = useState([]);
  const [recycler, setRecycler] = useState({});
  const [recyclerLedger, setRecyclerLedger] = useState([]);
  const [recList, setRecList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const [openingBalance, setOpeningBalance] = useState(null);
  const [closingBalance, setClosingBalance] = useState(null);
  const [totalAdjustments, setTotalAdjustments] = useState(null);
  const [totalRecepits, setTotalRecepits] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [
    {
      value: { data: recyclers = [] },
      getPError,
      fetchingP,
      getValueP,
      getError,
      fetching,
      getValue,
    },
    { doFetchRecyclerByPage, doGetRecyclerById },
  ] = useRecyclers();

  const [{ value, error }, { doGetRecyclerLedger }] = useRecyclerPaymentTermsAndCreditLimit();

  const initialValues = {
    startDate: '',
    endDate: '',
    scheduledTime: '',
  };

  const [refresh, setRefresh] = useState(false);

  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0);
  let result = [];

  useAsyncEffect(async () => {
    const recyclers = await doFetchRecyclerByPage();
    const recyclerResult = await doGetRecyclerById(id);
    setTotalCount(recyclers?.totalCount);
    setRecycler(recyclerResult);
    if (recyclers && recyclers.data) {
      setRecyclerList(recyclers?.data);
    } else {
      setRecyclerList([]);
    }
    const params = {
      recyclerId: id,
    };
    result = await doGetRecyclerLedger(params);
    if (result && result.status) {
      result = [];
      setLoading(false);
    } else {
      setRecyclerLedger(result);
      setLoading(false);
    }
  }, []);

  const onFilterChange = async (params) => {
    const recyclers = await doFetchRecyclerByPage(params);
    setTotalCount(recyclers?.totalCount);
    setRecyclerList(_.flatMap(recyclers.data));
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,

    onSubmit: (values) => {},
  });

  return (
    <PageContainer>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Row>
          <Col>
            <h4>{recycler.businessName} Recievables</h4>
            <p className="text-muted">To keep check on different payments</p>
            <hr style={{ borderTop: '1px solid' }} />
          </Col>
        </Row>
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <IndividualReceivablesTable
            loading={loading}
            refresh={refresh}
            setRefresh={setRefresh}
            recyclerLedger={recyclerLedger}
            recyclerList={recyclerList}
            totalCount={recyclerLedger?.length}
            onFilterChange={onFilterChange}
          />
        </div>
      </div>
    </PageContainer>
  );
};

IndividualReceivables.propTypes = {};

export default IndividualReceivables;
