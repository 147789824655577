/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Button, Badge, Row, Col } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { IoIosArrowDown } from '../../../assets/img/imageExport/images';
import { OverlayCell, DoShow } from '../GenericComponent';
import './MultiSelectFilterDropdown.scss';

const MultiSelectFilterDropdown = ({
  setFilter,
  filterValue,
  filterParam,
  operation,
  id,
  options,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (value?.length > 0) {
      const mapper = value?.map((i) => i?.id).join(',');
      setFilter({ value: mapper, operation, filterParam });
    }
  }, [value]);

  const MultiValueContainer = (value) => {
    if (isEmpty(value)) {
      return <span className="text-muted pl-1">Select.</span>;
    }
    return (
      <div style={{ fontSize: '9px', paddingLeft: '3px' }}>
        {value?.[0]?.name}
        {value?.length > 1 && (
          <OverlayCell
            overlay={
              <div style={{ fontSize: '14px' }}>{value?.map((a) => a?.name)?.join(', ')}</div>
            }>
            <Badge
              style={{
                borderRadius: '15px',
                width: '16px',
                height: '16px',
                lineHeight: '11px',
                border: '1px solid #dcdcdc',
                fontSize: '7px',
              }}>
              +{value?.length - 1}
            </Badge>
          </OverlayCell>
        )}
      </div>
    );
  };

  const removingValues = (id, values) => {
    setValue((prev) => {
      const removeDuplicate = prev?.filter((i) => i?.id !== id);
      return removeDuplicate;
    });
    return '';
  };

  const checkBoxHandler = (e, option) => {
    if (e?.target?.checked) {
      setValue([option, ...value]);
      setShowMenu(false);
    } else {
      removingValues(e?.target?.name, value);
      setShowMenu(false);
    }
  };

  const checkBoxComponent = (option) => {
    return (
      <>
        <Row className="p-1 pl-2">
          <Col md={2}>
            <input
              type="checkbox"
              style={{ marginTop: '7px' }}
              name={option?.id}
              checked={value?.find((i) => i?.id === option?.id)}
              onClick={(e) => {
                checkBoxHandler(e, option);
              }}
            />
          </Col>
          <Col className="options">{option?.name}</Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div style={{ position: 'relative' }} className="w-100">
        <Button className="containerBtn d-flex" onClick={() => setShowMenu((prev) => !prev)}>
          <div className="search-bar" value="h">
            {MultiValueContainer(value)}
          </div>
          <IoIosArrowDown className="dropdown-indicator" />
        </Button>
        <DoShow show={showMenu}>
          <div className="dropdown-container">
            {options?.map((i) => {
              return <div key={i?.name}>{checkBoxComponent(i)}</div>;
            })}
          </div>
        </DoShow>
      </div>
    </>
  );
};

export default MultiSelectFilterDropdown;
