import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ButtonLoading } from '../../../shared/components';

const DeletePoModal = ({ show, setShow, doDelete, loading, id, error }) => {
  return (
    <Modal show={show} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4">
        <h5>Delete Purchase Order?</h5>
        <p className="mt-2">
          Are you sure you want to delete the Purchase Order?
          <br />
          Once the Purchase Order is deleted, the action can&apos;t be undone
        </p>
        <div className="p-2">
          <Button variant="secondary" className="pr-5 pl-5 mr-3" onClick={() => setShow(null)}>
            Cancel
          </Button>
          <ButtonLoading
            loading={loading}
            className="pr-3 pl-3"
            variant="danger"
            onClick={() => doDelete(id)}>
            Delete Purchase Order
          </ButtonLoading>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DeletePoModal.propTypes = {};

export default DeletePoModal;
