import React from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import ButtonLoading from './Buttons/ButtonLoading';
import { DoShow, PrimaryButton } from './GenericComponent';

const PopupModal = ({
  show,
  toggleShow,
  title,
  children,
  buttons,
  isMobile,
  className,
  ...props
}) => {
  return (
    <Modal
      show={show}
      onHide={toggleShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="className"
      {...props}>
      {title && (
        <Modal.Header closeButton>
          <h4 className="pl-2">{title}</h4>
        </Modal.Header>
      )}
      <Modal.Body className="p-0 pl-4 pr-4 pb-4">
        {children}
        {isMobile &&
          buttons?.map((btn) => (
            <PrimaryButton
              label={btn?.name}
              onClick={btn?.onClick}
              buttonStyle={{ width: '100%' }}
              disabled={btn?.disabled}
            />
          ))}
      </Modal.Body>
      {!isMobile && (
        <DoShow show={buttons}>
          <Modal.Footer>
            {buttons?.map((btn) => (
              <ButtonLoading
                variant={btn?.variant}
                className={btn?.className}
                onClick={btn?.onClick}
                disabled={btn?.disabled}
                loading={btn?.loading}>
                {btn?.name}
              </ButtonLoading>
            ))}
          </Modal.Footer>
        </DoShow>
      )}
    </Modal>
  );
};

export default PopupModal;
