import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './PromptModal.scss';
import classNames from 'classnames';
import { ButtonLoading } from '..';
import { PrimaryButton, SecondaryButton } from '../GenericComponent';

const PromptModal = (props) => {
  const {
    show,
    onHide,
    title,
    text,
    subText,
    hideDismissBtn,
    hideSuccessBtn,
    dismissBtnText,
    successBtnText,
    dismissBtnClass,
    successBtnClass,
    onDismiss,
    onSuccess,
    variant,
    disabled,
    loading,
    isMobile,
    buttonLoading,
  } = props;

  return (
    <Modal show={show} onHide={onDismiss} className="modal-small PromptModal" centered>
      <Modal.Header closeButton={loading === false}>
        <Modal.Title>{title || ''}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{text}</p>
        {subText && <p style={{ color: '#607581' }}>{subText}</p>}
      </Modal.Body>

      <Modal.Footer style={isMobile && { justifyContent: 'end' }}>
        {isMobile ? (
          <>
            {!hideDismissBtn && (
              <SecondaryButton
                label={dismissBtnText || 'No'}
                onClick={onDismiss}
                buttonStyle={{ marginRight: '5px' }}
                disabled={disabled}
              />
            )}
            {!hideSuccessBtn && (
              <PrimaryButton
                loading={loading}
                buttonLoading={buttonLoading}
                label={successBtnText || 'Yes'}
                disabled={disabled || loading}
                onClick={onSuccess}
              />
            )}
          </>
        ) : (
          <>
            {!hideDismissBtn && (
              <SecondaryButton
                label={dismissBtnText || 'No'}
                onClick={onDismiss}
                buttonStyle={{ marginRight: '5px' }}
                disabled={disabled}
              />
            )}
            {!hideSuccessBtn && (
              <ButtonLoading
                className={classNames('btn-wide', successBtnClass)}
                variant={variant || 'primary'}
                disabled={disabled || loading}
                loading={loading}
                onClick={onSuccess}>
                {successBtnText || 'Yes'}
              </ButtonLoading>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default PromptModal;
