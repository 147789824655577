/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable */
/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo, useState } from 'react';
import {
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';
import { capitalize, flatMap, isEmpty, keyBy, map, sumBy } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  StringParam,
  useQueryParams,
  NumberParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import { useSticky } from 'react-table-sticky';
import * as _ from 'lodash';
import { Badge, Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import { ColumnSort, Content, Loader, PaginationComponent } from '../../shared/components';
import {
  Address,
  Amount,
  HasDesktop,
  Items,
  OverlayCell,
  TotalPoQuantity,
} from '../../shared/components/GenericComponent';
import {
  DateRangeFilter,
  InputColumnFilter,
  PoSelectStatusColumnFilter,
  SelectCityCoulmnFilter,
  SelectColumnFilter,
  SelectStatusColumnFilter,
} from '../../shared/components/FilterComponents';
import {
  convertToTableFilters,
  debounce,
  getPoOrderItems,
  getPaidStatus,
  hasStatus,
  parseTableFilters,
  percentage,
  toFloat,
  toLocalDate,
  toPaisa,
  toRupees,
  toNumber,
} from '../../shared/utils/Helper';
import {
  ASSESSMENT_STATUS,
  ORDER_STATUS,
  PAGE_CONFIG,
  PURCHASE_ORDER_STATUS,
  SALE_ORDER_STATUS,
} from '../../shared/constants/Constants';
import { useAsyncEffect, useCatalog, useStakeholders } from '../../hooks';
import { useRecyclers } from '../../hooks/hooks';
import { ReactComponent as IconFirstOrder } from '../../assets/img/first-order.svg';
import {
  getRecyclerIdsByName,
  getTransporterIdsByName,
  getSortBy,
} from '../../shared/utils/filters.utils';
import { CommaArrayParam } from '../../shared/utils/queryParams.utils';
import './RecyclerReceivablesTable.scss';
import EmptyIcon from '../../assets/img/item-empty.png';

const RecyclerReceivablesTableNew = (props) => {
  const {
    receivalbesList,
    setReceivalbesList,
    recyclerList,
    receivablesData,
    setReceivablesData,
    loading,
    totalCount,
    onFilterChange,
    refresh,
    setRefresh,
    fromDate,
    toDate,
  } = {
    ...props,
  };
  const [
    {
      value: { data: recyclers = [] },
      getPError,
      fetchingP,
      getValueP,
    },
    { doFetchRecycler },
  ] = useRecyclers();
  let reList = recyclerList;
  //const { recyclers } = useStakeholders();
  const history = useHistory();
  var currentDate = new Date();
  const queryParamsConfig = {
    id: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, undefined),
    size: withDefault(NumberParam, PAGE_CONFIG.size),
  };
  const [filters, setFilters] = useQueryParams(queryParamsConfig);

  useAsyncEffect(async () => {
    // set default filter to table
    setAllFilters(convertToTableFilters(filters));
  }, []);

  /**
   * Effect on filter change
   */

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (refresh) {
      setAllFilters([]);
      applyFilters(filters);
      setRefresh(false);
    }
  }, [refresh]);

  const applyFilters = React.useCallback(
    debounce((newFilters) => {
      const latestFilters = { ...newFilters };
      if (latestFilters.id) {
        const test = getRecyclerIdsByName(reList, latestFilters.id)?.toString();
        if (test.length > 0) {
          latestFilters.id = test;
        } else {
          latestFilters.id = '';
        }
      }
      onFilterChange(latestFilters);
    }, 130),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Buyer / Company Name',
        accessor: 'id',
        sticky: 'left',
        width: 300,
        Filter: InputColumnFilter,
        Cell: (cellInfo) => {
          const recycler = _.find(recyclerList, ['id', cellInfo?.value]);
          return (
            <Button
              variant="link"
              style={{ color: 'blue' }}
              className="float-left mr-5"
              onClick={() => {
                history.push(`/recyclerReceivables/${recycler.id}/edit`);
              }}>
              <u>#{recycler.businessName}</u>
            </Button>
          );
        },
      },
      {
        Header: 'Payment Term',
        accessor: 'paymentTerm',
        sticky: 'left',
        className: 'header-right-border',
        width: 150,
        //Filter: InputColumnFilter,
        Cell: (cell) => {
          return cell?.row?.original?.recyclerPaymentTerm?.paymentTerm || '';
        },
      },
      {
        Header: 'Credit Limit',
        accessor: 'recyclerId',
        className: 'header-right-border',
        width: 140,
        Cell: (cellInfo) => {
          const rGroup = receivablesData?.reduce(
            (entryMap, e) =>
              entryMap?.set(e?.recyclerId, [...(entryMap.get(e?.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          if (recycler) {
            let amount = '';
            if (recycler?.length > 0) {
              amount = toRupees(recycler[0].creditLimit).toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
              });
            } else {
              amount = '-';
            }
            return amount;
          } else {
            return '0';
          }
        },
      },
      {
        Header: 'Opening Balance',
        accessor: 'openingBalance',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivablesData?.reduce(
            (entryMap, e) =>
              entryMap?.set(e?.recyclerId, [...(entryMap.get(e?.recyclerId) || []), e]),
            new Map()
          );
          const resultData = rGroup?.get(cellInfo?.row?.original?.id);
          let previousEndDate = new Date(fromDate);
          previousEndDate = previousEndDate.setDate(fromDate.getDate() - 1);
          let previousClosingBalance = 0;
          resultData?.filter((item) => {
            if (item && item.createdAt != null) {
              if (previousEndDate >= new Date(item.createdAt)) {
                previousClosingBalance += item.finalInvoiceAmount - item.recepits;
              }
            }
          });
          if (resultData) {
            return toRupees(previousClosingBalance, false).toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
            });
          } else {
            return '0';
          }
        },
      },
      {
        Header: 'Total Sales',
        accessor: 'totalSales',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          if (recycler) {
            var totalSales = 0;
            recycler.forEach((item) => {
              totalSales += item.totalAmount;
            });
            return toRupees(totalSales, false).toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
            });
          } else {
            return '0';
          }
        },
      },
      {
        Header: 'Recepits',
        accessor: 'recepits',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          if (recycler) {
            var totalRecepits = 0;
            recycler.forEach((item) => {
              totalRecepits += item.recepits;
            });
            return toRupees(totalRecepits, false).toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
            });
          } else {
            return '0';
          }
        },
      },
      {
        Header: 'Total Adjustments',
        accessor: 'adjustments',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          if (recycler) {
            var totalDebits = 0;
            recycler.forEach((item) => {
              totalDebits += item.debitNoteAmount;
            });
            return toRupees(totalDebits, false).toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
            });
          } else {
            return '0';
          }
        },
      },
      {
        Header: 'Closing Balance',
        accessor: 'closingBalance',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const groupedData = receivablesData?.reduce(
            (entryMap, e) =>
              entryMap?.set(e?.recyclerId, [...(entryMap.get(e?.recyclerId) || []), e]),
            new Map()
          );
          const resultData = groupedData?.get(cellInfo?.row?.original?.id);
          let previousEndDate = new Date(fromDate);
          previousEndDate = previousEndDate.setDate(fromDate.getDate() - 1);
          let previousClosingBalance = 0;
          resultData?.filter((item) => {
            if (item && item.createdAt != null) {
              if (previousEndDate >= new Date(item.createdAt)) {
                previousClosingBalance += item.finalInvoiceAmount - item.recepits;
              }
            }
          });
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          if (recycler) {
            var totalSales = 0;
            var totalRecepits = 0;
            var totalDebits = 0;
            recycler.forEach((item) => {
              totalSales += item.totalAmount;
              totalRecepits += item.recepits;
              totalDebits += item.debitNoteAmount;
            });
            const finalClosingBalance =
              previousClosingBalance + totalSales - totalRecepits - totalDebits;
            return toRupees(finalClosingBalance, false).toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
            });
          } else {
            return '0';
          }
        },
      },
      {
        Header: '<30 Days>',
        accessor: 'lt30Days',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          let sales = 0;
          let debit = 0;
          let recepits = 0;
          if (recycler) {
            recycler.forEach((item) => {
              if (item?.invoiceDueDate) {
                const dueDate = new Date(item.invoiceDueDate);
                const diff_in_time = currentDate.getTime() - dueDate.getTime();
                const diff_in_days = Math.ceil(diff_in_time / (1000 * 3600 * 24));
                if (diff_in_days <= 30) {
                  sales += item.totalAmount;
                  debit += item.debitNoteAmount;
                  recepits += item.recepits;
                }
              }
            });
          }
          return toRupees(sales - recepits - debit, false).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
          });
        },
      },
      {
        Header: '31 to 60 Days',
        accessor: 'tonesdays',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          let sales = 0;
          let debit = 0;
          let recepits = 0;
          if (recycler) {
            recycler.forEach((item) => {
              if (item?.invoiceDueDate != null && item?.invoiceDueDate) {
                const dueDate = new Date(item.invoiceDueDate);
                const diff_in_time = currentDate.getTime() - dueDate.getTime();
                const diff_in_days = Math.ceil(diff_in_time / (1000 * 3600 * 24));
                if (diff_in_days > 30 && diff_in_days <= 60) {
                  sales += item.totalAmount;
                  debit += item.debitNoteAmount;
                  recepits += item.recepits;
                }
              }
            });
          }

          return toRupees(sales - recepits - debit, false).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
          });
        },
      },
      {
        Header: '61 to 90 Days',
        accessor: 'stondays',
        className: 'header-right-border',
        width: 130,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          let sales = 0;
          let debit = 0;
          let recepits = 0;
          if (recycler) {
            recycler.forEach((item) => {
              if (item?.invoiceDueDate != null && item?.invoiceDueDate) {
                const dueDate = new Date(item.invoiceDueDate);
                const diff_in_time = currentDate.getTime() - dueDate.getTime();
                const diff_in_days = Math.ceil(diff_in_time / (1000 * 3600 * 24));
                if (diff_in_days > 60 && diff_in_days <= 90) {
                  sales += item.totalAmount;
                  debit += item.debitNoteAmount;
                  recepits += item.recepits;
                }
              }
            });
          }
          return toRupees(sales - recepits - debit, false).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
          });
        },
      },
      {
        Header: '90+ Days',
        accessor: 'nplusdays',
        sticky: 'right',
        className: 'header-right-border',
        width: 100,
        Cell: (cellInfo) => {
          const rGroup = receivalbesList?.reduce(
            (entryMap, e) => entryMap.set(e.recyclerId, [...(entryMap.get(e.recyclerId) || []), e]),
            new Map()
          );
          const recycler = rGroup?.get(cellInfo?.row?.original?.id);
          let sales = 0;
          let debit = 0;
          let recepits = 0;
          if (recycler) {
            recycler.forEach((item) => {
              if (item?.invoiceDueDate != null && item?.invoiceDueDate) {
                const dueDate = new Date(item.invoiceDueDate);
                const diff_in_time = currentDate.getTime() - dueDate.getTime();
                const diff_in_days = Math.ceil(diff_in_time / (1000 * 3600 * 24));
                if (diff_in_days > 90) {
                  sales += item.totalAmount;
                  debit += item.debitNoteAmount;
                  recepits += item.recepits;
                }
              }
            });
          }
          return toRupees(sales - recepits - debit, false).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
          });
        },
      },
    ],
    [recyclerList, receivalbesList, receivablesData, fromDate, toDate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, filters: tableFilters, sortBy },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        pageSize: PAGE_CONFIG.size,
      },
      data: recyclerList || [],
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / PAGE_CONFIG.size),
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    usePagination,
    useSticky
  );

  useEffect(() => {
    const _filters = parseTableFilters(tableFilters);
    const sort = getSortBy(sortBy);
    const updatedFilters = {
      ..._filters,
      size: filters.size,
      page: 0,
      sort,
    };
    setFilters(updatedFilters, 'replace');
  }, [tableFilters, sortBy]);

  useEffect(() => {
    setFilters({ ...filters, size: filters.size, page: pageIndex }, 'replace');
  }, [pageIndex]);

  function sortCheck(column) {
    return (
      column.id === 'poDate' ||
      column.id === 'quantity' ||
      column.id === 'total' ||
      column.id === 'saleOrderMapped'
    );
  }

  return (
    <React.Suspense fallback={<Loader />}>
      <div {...getTableProps()} className="RecyclerReceivablesTable table sticky">
        <div className="header">
          {headerGroups.map((headerGroup, rowNum) => (
            <>
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th border-right">
                    <div className="d-flex flex-row align-items-center">
                      {column.render('Header')}
                      {column.canSort && !sortCheck(column) && <ColumnSort column={column} />}
                    </div>
                  </div>
                ))}
              </div>
              <div {...headerGroup.getHeaderGroupProps()} className="tr filter-row">
                {headerGroup.headers.map((column, k) => (
                  <div
                    {...column.getHeaderProps({ style: { width: column.width } })}
                    className="th">
                    {column.Filter ? column.render('Filter') : null}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          <Content
            loading={loading}
            emptyIcon={EmptyIcon}
            isFiltered={tableFilters?.length > 0}
            emptyResultMessage="There are no Sale order for selected criteria"
            hasData={!!recyclerList?.length}
            emptyMessage="There are no sale orders received from the Sellers">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className="td align-items-center">
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Content>
        </div>
      </div>
      <PaginationComponent
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        loading={loading && recyclerList?.length}
        gotoPage={gotoPage}
        currentPage={pageIndex}
        numberOfPages={pageOptions.length}
        totalCount={totalCount}
      />
    </React.Suspense>
  );
};

export default React.memo(RecyclerReceivablesTableNew);
