import React, { Suspense, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Form } from 'formik';
import { flatMap, uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { PageContainer, FormField, Loader, MpDatePicker } from '../../shared/components';
import MaterialTypeDropdown from '../../shared/components/Dropdowns/MaterialTypeDropdown';
import { useCatalog } from '../../hooks';
import './FinanceWorkarea.scss';
import StatusCard from '../../shared/components/Cards/StatusCard';
import SummaryCard from '../../shared/components/Cards/SummaryCard';
import { useFinanceDashboard } from '../../hooks/hooks';
import { convertToLocalDateTime, toLocalDate, toServerDate } from '../../shared/utils/Helper';
import { CalendarIcon } from '../../assets/img/imageExport/images';
import { PrimaryButton } from '../../shared/components/GenericComponent';

const FinanceWorkarea = ({ props }) => {
  const [selectedType, setSelectedType] = useState(0);
  const [typeOptions, setTypeOptions] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState(
    toServerDate(moment().subtract(6, 'month').toDate())
  );
  const [endDateFilter, setEndDateFilter] = useState(toServerDate(new Date()));
  const { items } = useCatalog();
  const history = useHistory();

  const [{ loading, error, value: financeData }, { doGetFinanceDashboard }] = useFinanceDashboard();
  const [refresh, setRefresh] = useState(false);
  const isAggregatorPayments = true;

  useEffect(() => {
    const payload = {
      startDateFilter: startDateFilter
        ? new Date(startDateFilter).toISOString()
        : new Date(toServerDate(moment().subtract(6, 'month').toDate())).toISOString(),
      endDateFilter: endDateFilter
        ? new Date(endDateFilter).toISOString()
        : new Date(toServerDate(new Date())).toISOString(),
    };
    doGetFinanceDashboard(payload);
  }, [startDateFilter, endDateFilter]);

  useEffect(() => {
    let options = flatMap(items).map((item) => item?.categoryDTO?.materialType);
    options = uniqBy(options, (e) => e?.id);
    setTypeOptions([{ name: 'All', id: 0 }, ...options]);
  }, []);

  const typeHandler = (e) => {
    if (e !== null) {
      setSelectedType(e?.id);
    } else {
      setSelectedType('');
    }
  };

  useEffect(() => {
    if (typeOptions?.length > 0) {
      setSelectedType(typeOptions[0]);
    }
  }, [typeOptions]);

  const tranformedPendingAggregatorPayments = financeData?.pending_aggregator_payments && {
    pending_initiation: [
      financeData?.pending_aggregator_payments?.pending_initiation_count || '0',
      financeData?.pending_aggregator_payments?.pending_initiation_amount || '0',
    ],
    pending_processing: [
      financeData?.pending_aggregator_payments?.pending_processing_count || '0',
      financeData?.pending_aggregator_payments?.pending_processing_amount || '0',
    ],
    pending_approval: [
      financeData?.pending_aggregator_payments?.pending_approval_count || '0',
      financeData?.pending_aggregator_payments?.pending_approval_amount || '0',
    ],
  };

  const transformedReceivablesData = financeData?.receivables && {
    outstanding_amount: [
      financeData?.receivables?.outstandingCount || '0',
      financeData?.receivables?.outstandingAmount || '0',
    ],
    total_overdue: [
      financeData?.receivables?.overDueCount || '0',
      financeData?.receivables?.outstandingAmount - financeData?.receivables?.current || '0',
    ],
    unapplied_credits: [
      financeData?.receivables?.unappliedCount || '0',
      financeData?.receivables?.unusedAmount || '0',
    ],
    received_today: [
      financeData?.receivables?.receivedCount || '0',
      financeData?.receivables?.todayReceived || '0',
    ],
  };

  const transformedPendingVendorOnboarding = financeData?.pending_vendor_onboarding && {
    agreement: financeData?.pending_vendor_onboarding?.agreement || '0',
    VKYC: financeData?.pending_vendor_onboarding?.vkyc || '0',
    OSV: financeData?.pending_vendor_onboarding?.osv || '0',
    document: financeData?.pending_vendor_onboarding?.document || '0',
  };

  const transformedPendingTransactionVer = financeData?.pending_transaction_verification && {
    shipment_verification_and_processing:
      financeData?.pending_transaction_verification?.invoice_and_Bills || '0',
    buyer_notes: financeData?.pending_transaction_verification?.buyer_notes || '0',
    seller_notes: financeData?.pending_transaction_verification?.seller_notes || '0',
    shipment_completion: financeData?.pending_transaction_verification?.shipments_completed || '0',
  };

  function dashboardDateFilter(
    startDateFilter,
    endDateFilter,
    setStartDateFilter,
    setEndDateFilter
  ) {
    return (
      <div>
        <MpDatePicker
          // parentEl="#orderDate"
          // id="orderDate"
          initialSettings={{
            autoUpdateInput: false,
            locale: {
              cancelLabel: 'Clear',
            },
            maxDate: new Date(),
            alwaysShowCalendars: true,
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
              ],
              'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
              'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
              'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
              ],
              'Last 6 Months': [moment().subtract(6, 'month').toDate(), moment().toDate()],
            },
          }}
          inputClass="date-search-field"
          startDate={startDateFilter || ''}
          endDate={endDateFilter || ''}
          onApply={({ startDate, endDate }, $event) => {
            setStartDateFilter(startDate);
            setEndDateFilter(endDate);
          }}
          onCancel={(event, picker) => {
            picker.element.val('');
            setStartDateFilter(undefined);
            setEndDateFilter(undefined);
          }}
        />
      </div>
    );
  }
  return loading ? (
    <div className="d-flex flex-column loader justify-content-center h-100">
      <Loader />
    </div>
  ) : (
    <div className="FinanceWorkArea" style={{ height: '100vh', overflowX: 'hidden' }}>
      <div style={{ borderRadius: '8px', backgroundColor: 'white' }}>
        <div className="p-3">
          <Row className="align-items-center">
            <Col md={9}>
              <h3 style={{ fontSize: '24px' }}>Finance Workarea</h3>
            </Col>
            <Col md={3} className="text-right">
              <div>
                {dashboardDateFilter(
                  startDateFilter,
                  endDateFilter,
                  setStartDateFilter,
                  setEndDateFilter
                )}
              </div>
            </Col>
          </Row>
          <>
            <Card
              className="pt-2 pb-3 px-4 m-4"
              style={{
                borderRadius: '12px',
                backgroundColor: '#F2F4F7',
                border: 'none',
              }}>
              <h5 className="sub-heading-style">Pending Vendor Onboarding</h5>
              <Row>
                {transformedPendingVendorOnboarding &&
                  Object.entries(transformedPendingVendorOnboarding).map(([key, value]) => {
                    return (
                      <Col>
                        <StatusCard title={key} count={value} />
                      </Col>
                    );
                  })}
              </Row>
            </Card>
            <Card
              className="pt-2 pb-3 px-4 m-4"
              style={{
                borderRadius: '12px',
                backgroundColor: '#F2F4F7',
                border: 'none',
              }}>
              <h5 className="sub-heading-style">Pending Transaction Verification</h5>
              <Row>
                {transformedPendingTransactionVer &&
                  Object.entries(transformedPendingTransactionVer).map(([key, value]) => {
                    return (
                      <Col>
                        <StatusCard
                          title={key}
                          count={value}
                          onClick={() => {
                            history.push({
                              pathname: '/orders/shipment',
                              state: {
                                startDateFilter,
                                endDateFilter,
                                key,
                              },
                            });
                          }}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </Card>
            <Card
              className="pt-2 pb-3 px-4 m-4"
              style={{
                borderRadius: '12px',
                backgroundColor: '#F2F4F7',
                border: 'none',
              }}>
              <h5 className="sub-heading-style">Pending Aggregator Payments</h5>
              <Row>
                {tranformedPendingAggregatorPayments &&
                  Object.entries(tranformedPendingAggregatorPayments).map(([key, value]) => {
                    return (
                      <Col>
                        <SummaryCard
                          title={key}
                          count={value[0]}
                          amount={value[1]}
                          onClick={() => {
                            history.push({
                              pathname: `/payments/aggregatorpayments`,
                              state: {
                                startDateFilter,
                                endDateFilter,
                                key,
                              },
                            });
                          }}
                          isAggregatorPayments
                        />
                      </Col>
                    );
                  })}
              </Row>
            </Card>
          </>
        </div>
      </div>
      <div style={{ borderRadius: '8px', backgroundColor: 'white' }} className="mt-2 p-4">
        <>
          <Row className="d-flex flex row pt-2 pb-2 px-4 mb-1">
            <Col md={6}>
              <h4>Receivables</h4>
            </Col>
            <Col md={6}>
              <PrimaryButton
                label="View Details"
                onClick={() => {
                  history.push({
                    pathname: '/receivables',
                  });
                }}
                buttonStyle={{
                  backgroundColor: '#FFFFFF',
                  borderColor: '#D0D5DD',
                  borderRadius: '8px',
                  float: 'right',
                }}
                textStyle={{ color: '#344054' }}
              />
            </Col>
          </Row>
          <Row className="px-4">
            {transformedReceivablesData &&
              Object.entries(transformedReceivablesData).map(([key, value]) => {
                return (
                  <Col>
                    <SummaryCard title={key} count={value[0]} amount={value[1]} />
                  </Col>
                );
              })}
          </Row>
        </>
      </div>
    </div>
  );
};

export default FinanceWorkarea;
