import React, { useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NumberParam, StringParam, withDefault } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import {
  useTable,
  useSortBy,
  useFilters,
  useBlockLayout,
  useResizeColumns,
  usePagination,
} from 'react-table';
import '../RequisitionsPage/RequisitionList.scss';
import * as _ from 'lodash';
import { useSticky } from 'react-table-sticky';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import {
  InputColumnFilter,
  SelectColumnFilter,
  SelectQuotesStatusColumnFilter,
} from '../../shared/components/FilterComponents';
import { ColumnSort, Content, Loader, PaginationComponent } from '../../shared/components';
import EmptyIcon from '../../assets/img/item-empty.png';
import { getSortBy } from '../../shared/utils/filters.utils';

import {
  Address,
  Amount,
  Items,
  itemUnitDisplay,
  KamRequisitionAndQuoteStatus,
  LinkNavigate,
} from '../../shared/components/GenericComponent';
import {
  convertToTableFilters,
  debounce,
  parseTableFilters,
  toLocalDate,
} from '../../shared/utils/Helper';
import { PAGE_CONFIG, QUOTES_STATUS } from '../../shared/constants/Constants';
import Quantity from '../../shared/components/Quantity';
import { useAsyncEffect, useCatalog } from '../../hooks';
import { ItemDropdown } from '../../shared/components/Dropdowns';

const QuotesListPage = ({
  quotesData,
  loading,
  totalCount,
  onFilterChange,
  refresh,
  setRefresh,
}) => {
  const queryParamsConfig = {
    page: withDefault(NumberParam, undefined),
    size: withDefault(NumberParam, PAGE_CONFIG.size),
    sort: withDefault(StringParam, 'createdOn,desc'),
    id: withDefault(StringParam, undefined),
    requisitionId: withDefault(StringParam, undefined),
    sellerName: withDefault(StringParam, undefined),
    itemId: withDefault(StringParam, undefined),
    status: withDefault(StringParam, undefined),
  };

  const [filters, setFilters] = useQueryParams(queryParamsConfig);
  const { items } = useCatalog();

  const defaultColumn = React.useMemo(
    () => ({
      Filter: InputColumnFilter,
    }),
    []
  );

  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    return () => {
      if (loading) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useAsyncEffect(async () => {
    // set default filter to table
    setAllFilters(convertToTableFilters(filters));
  }, []);

  /**
   * Effect on filter change
   */

  useEffect(() => {
    if (refresh) {
      setAllFilters([]);
      setRefresh(false);
    }
  }, [refresh]);

  useAsyncEffect(async () => {
    // set default filter to table
    setAllFilters(convertToTableFilters(filters));
  }, []);

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  const applyFilters = React.useCallback(
    debounce((newFilters) => {
      const latestFilters = { ...newFilters };
      onFilterChange(latestFilters);
    }, 100),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Quote Id',
        accessor: 'id',
        sticky: 'left',
        width: 130,
        Filter: InputColumnFilter,
        Cell: ({ row }) => row?.original?.id,
      },
      {
        Header: ' Requisition ID',
        sticky: 'left',
        width: 130,
        accessor: 'requisitionId',
        Filter: InputColumnFilter,
        Cell: ({ row }) => <LinkNavigate flag="REQ" ID={row?.original?.requisitionId} />,
      },
      {
        Header: 'Quote Date',
        accessor: 'createdOn',
        width: 150,
        Filter: false,
        Cell: ({ row }) => toLocalDate(row?.original?.createdOn),
      },
      {
        Header: 'Item Name',
        accessor: 'itemId',
        disableSortBy: true,
        canFilter: true,
        width: 250,
        Filter: SelectColumnFilter,
        Cell: ({ row }) => {
          return <Items value={[items?.[row?.original?.itemId]]} />;
        },
      },
      {
        Header: 'Seller Name',
        accessor: 'sellerName',
        disableSortBy: true,
        Filter: InputColumnFilter,
        Cell: ({ row }) => {
          return (
            <div style={{ textAlign: 'left', width: '100%' }} className="p-1">
              {row?.original?.aggregatorDTO?.businessName || 'NA'}
            </div>
          );
        },
      },
      {
        Header: 'Seller location',
        accessor: 'location',
        disableSortBy: true,
        Filter: false,
        Cell: ({ row }) => (
          <Address
            short
            value={row?.original?.aggregatorDTO?.addresses?.find(
              (e) => e?.isDefault === true && e?.isPickup === true
            )}
          />
        ),
      },
      {
        Header: 'Seller Quote Price',
        accessor: (d) => d?.rate,
        disableSortBy: true,
        Filter: false,
        Cell: ({ row }) => <Amount value={row?.original?.rate} />,
      },
      {
        Header: 'Buyer landing Price',
        disableSortBy: true,
        accessor: (d) => d?.buyerLandingPrice,
        Filter: false,
        Cell: ({ row }) => <Amount value={row?.original?.buyerLandingPrice} />,
      },
      {
        Header: 'Shipment days',
        disableSortBy: true,
        accessor: (d) => d?.shipmentDays,
        Filter: false,
        Cell: ({ row }) => `${row?.original?.shipmentDays || 0} Days`,
      },
      {
        Header: 'Quantity',
        disableSortBy: true,
        accessor: (d) => d?.qty,
        Filter: false,
        Cell: ({ row }) => (
          <Row>
            <Quantity value={row?.original?.qty} />
            <span className="ml-2">{itemUnitDisplay(row?.original?.itemId)}</span>
          </Row>
        ),
      },
      {
        Header: 'Status',
        disableSortBy: true,
        accessor: 'status',
        sticky: 'right',
        width: 220,
        Filter: SelectQuotesStatusColumnFilter,
        Cell: ({ row }) => (
          <KamRequisitionAndQuoteStatus status={QUOTES_STATUS[row?.original?.status]} />
        ),
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, filters: tableFilters, sortBy },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        pageSize: PAGE_CONFIG.size,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / PAGE_CONFIG.size),
      data: quotesData || [],
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
      autoResetPage: false,
    },
    useBlockLayout,
    useResizeColumns,
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    useSticky
  );

  useEffect(() => {
    const _filters = parseTableFilters(tableFilters);
    const sort = getSortBy(sortBy);
    const updatedFilters = {
      ..._filters,
      size: filters.size,
      page: 0,
      sort,
    };
    setFilters(updatedFilters, 'replace');
  }, [tableFilters, sortBy]);

  useEffect(() => {
    setFilters({ ...filters, size: filters.size, page: pageIndex }, 'replace');
  }, [pageIndex]);

  function sortCheck(column) {
    return column.id === 'id';
  }

  return (
    <React.Suspense fallback={<Loader />}>
      <div {...getTableProps()} className="RequisitionList table sticky">
        <div className="header">
          {headerGroups.map((headerGroup, rowNum) => (
            <>
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column, idx) => (
                  <div
                    key={idx}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="th">
                    <div className="d-flex flex-row align-items-center">
                      {column.render('Header')}
                      {column.canSort && sortCheck(column) && <ColumnSort column={column} />}
                    </div>
                  </div>
                ))}
              </div>
              <div {...headerGroup.getHeaderGroupProps()} className="tr filter-row">
                {headerGroup.headers.map((column, k) => (
                  <div
                    {...column.getHeaderProps({ style: { width: column?.width } })}
                    className="th">
                    {column.Filter ? column.render('Filter') : null}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body">
          <Content
            loading={loading}
            emptyIcon={EmptyIcon}
            isFiltered={tableFilters?.length > 0}
            emptyResultMessage="There are no Requisitions for selected criteria"
            hasData={quotesData?.length > 0}
            emptyMessage="There are no Requisitions">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className="td align-items-center">
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Content>
        </div>
      </div>
      <PaginationComponent
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        loading={loading && quotesData?.length}
        gotoPage={gotoPage}
        currentPage={pageIndex}
        numberOfPages={pageOptions.length}
        totalCount={totalCount}
      />
    </React.Suspense>
  );
};
export default QuotesListPage;
