import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import './Buttons.scss';

const ButtonLoading = ({ children, loading, className, disabled, ...rest }) => {
  return (
    <Button className={`ButtonLoading ${className}`} disabled={loading || disabled} {...rest}>
      {loading && <Spinner size="sm" animation="border" />}
      {children}
    </Button>
  );
};

ButtonLoading.propTypes = {
  loading: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default ButtonLoading;
