import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {
  useFilters,
  useSortBy,
  useTable,
  useBlockLayout,
  useResizeColumns,
  usePagination,
} from 'react-table';
import { flatMap } from 'lodash';
import { StringParam, useQueryParams, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import {
  DateRangeFilter,
  InputColumnFilter,
  SaleOrderDocumentStatus,
  SelectStatusColumnFilter,
} from '../../shared/components/FilterComponents';
import { Content, PaginationComponent, Loader } from '../../shared/components';
import {
  convertToTableFilters,
  debounce,
  parseTableFilters,
  toLocalDateTime,
} from '../../shared/utils/Helper';
import EmptyIcon from '../../assets/img/item-empty.png';
import { DoShow, LinkNavigate } from '../../shared/components/GenericComponent';
import { getSortBy } from '../../shared/utils/filters.utils';
import { useAsyncEffect, useStakeholders } from '../../hooks';
import { PAGE_CONFIG } from '../../shared/constants/Constants';
import HasRolePermission, { useRoleAuthority } from '../../shared/HasRolePermission';
import { ALL_MODULES } from '../../shared/constants/Modules';
import { ALL_PERMISSIONS } from '../../shared/constants/Permissions';
import './OrderAllocationTable.scss';
import { OrderStatusCell } from '../SaleOrder/SaleOrderList/SaleOrderList.cell';
import { DocumentStatusLabel } from './DocumentStatusLabel';
import { CommaArrayParam } from '../../shared/utils/queryParams.utils';
import { useOrderAllocation } from '../../hooks/hooks';

const OrderAllocationHoldList = ({
  loading,
  setShowManualAllocation,
  setEditItemObj,
  getSaleOrderAllocationList,
  tabKey,
  data,
  totalCount,
  orderLogs,
  setOrderLogs,
  showCompleteModal,
  setShowCompleteModal,
}) => {
  const { hasRoleAuth } = useRoleAuthority();
  const { kamUsers, currentAccount } = useStakeholders();
  const roleName = currentAccount?.authorities?.find(
    (item) => item === 'ROLE_MP_INVOICE_PROCESSOR'
  );
  const [
    { saleOrderAllocationLogsError, saleOrderAllocationLogsLoading, saleOrderAllocationLogsValue },
    { doGetSaleOrderAllocationLogs },
  ] = useOrderAllocation();

  const queryParamsConfig = {
    shipmentId: withDefault(StringParam, undefined),
    orderStatus: withDefault(StringParam, undefined),
    documentStatus: withDefault(StringParam, undefined),
    spocId: withDefault(StringParam, undefined),
    modifiedAt: withDefault(CommaArrayParam, undefined),
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, PAGE_CONFIG.size),
    sort: withDefault(StringParam, 'createdAt,desc'),
    modifiedBy: withDefault(StringParam, undefined),
  };

  const [filters, setFilters] = useQueryParams(queryParamsConfig);

  const history = useHistory();
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    return () => {
      if (loading) {
        abortControllerRef.current.abort();
      }
    };
  }, [loading]);

  useAsyncEffect(async () => {
    // Set default filter to table
    setAllFilters(convertToTableFilters(filters));
  }, []);
  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  useEffect(() => {
    setAllFilters([]);
    applyFilters(filters);
  }, [tabKey]);

  const applyFilters = useCallback(
    debounce((newFilters) => {
      if (tabKey === 'HOLD') {
        const latestFilters = { ...newFilters };
        getSaleOrderAllocationList(latestFilters);
      }
    }, 100),
    [tabKey]
  );

  const allocationLogsHandler = async (orderId) => {
    const response = await doGetSaleOrderAllocationLogs(orderId);
    setOrderLogs(response?.data);
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Shipment ID',
          accessor: 'shipmentId',
          canFilter: true,
          Filter: InputColumnFilter,
          width: '22%',
          Cell: (cell) => {
            return (
              <div style={{ textAlign: 'center' }}>
                <LinkNavigate
                  flag="SO"
                  ID={cell?.value}
                  style={{
                    ...(!hasRoleAuth(ALL_MODULES?.SHIPMENT, ALL_PERMISSIONS?.VIEW) && {
                      pointerEvents: 'none',
                    }),
                  }}
                />
                <br />
                <span>{cell?.row?.original?.dummyOrder ? '(Internal)' : ''}</span>
              </div>
            );
          },
        },
        {
          Header: 'Order Status',
          accessor: 'orderStatus',
          canFilter: true,
          Filter: SelectStatusColumnFilter,
          width: '22%',
          Cell: (cell) => {
            return (
              <OrderStatusCell fromScreen="ORDER_ALLOCATION" hideText order={cell?.row?.original} />
            );
          },
        },
        {
          Header: 'Document Status',
          accessor: 'documentStatus',
          canFilter: true,
          Filter: SaleOrderDocumentStatus,
          width: '22%',
          Cell: (cell) => {
            const docStatus = cell?.value;
            return <DocumentStatusLabel docStatus={docStatus} />;
          },
        },
        {
          Header: 'POC Name',
          accessor: 'spocId',
          width: '22%',
          Cell: (cell) => {
            const spoc = flatMap(kamUsers)?.find(
              (user) => user?.userId === cell?.row?.original?.aggregatorDTO?.spocId
            )?.firstName;
            return <span className="text-left">{spoc}</span>;
          },
        },
        {
          Header: 'Held By',
          accessor: 'modifiedBy',
          // canFilter: !roleName,
          // Filter: !roleName ? AccountsExucutiveUserFilter : null,
          width: '22%',
          Cell: (cell) => {
            const user = flatMap(kamUsers)?.find((user) => user?.userId === cell?.value)?.firstName;
            return <span className="text-left">{user}</span>;
          },
        },
        {
          Header: 'Held On',
          accessor: 'modifiedAt',
          canFilter: true,
          Filter: DateRangeFilter,
          width: '22%',
          Cell: (cell) => (
            <div className="text-left">{toLocalDateTime(cell?.value, 'DD-MMM-YYYY h:mm a')}</div>
          ),
        },
        {
          Header: 'Actions',
          accessor: 'action',
          width: '22%',
          Cell: (cell) => {
            const overLayPopover = (
              <Popover id="popover-positioned">
                <Popover.Content>
                  <ListGroup variant="flush">
                    {' '}
                    {hasRoleAuth(
                      ALL_MODULES?.ORDER_ALLOCATION,
                      ALL_PERMISSIONS?.ASSIGN_REASSIGN_SALEORDER
                    ) && (
                      <ListGroup.Item
                        action
                        onClick={() => {
                          setShowManualAllocation(true);
                          setEditItemObj(cell?.row?.original);
                          document.body.click();
                        }}>
                        <span className="EditIcon">Re-assign Task</span>
                      </ListGroup.Item>
                    )}
                    <ListGroup.Item
                      action
                      onClick={() => {
                        allocationLogsHandler(cell?.row?.original?.shipmentId);
                        setEditItemObj(cell?.row?.original);
                        document.body.click();
                      }}>
                      <span className="EditIcon">View Logs</span>
                    </ListGroup.Item>
                    <HasRolePermission
                      moduleName={ALL_MODULES?.ORDER_ALLOCATION}
                      permissionName={[ALL_PERMISSIONS?.COMPLETE_SHIPMENT]}>
                      <ListGroup.Item
                        action
                        onClick={() => {
                          setEditItemObj(cell?.row?.original);
                          setShowCompleteModal(true);
                          document.body.click();
                        }}>
                        <span className="EditIcon">Complete Order</span>
                      </ListGroup.Item>
                    </HasRolePermission>
                  </ListGroup>
                </Popover.Content>
              </Popover>
            );
            return (
              <div style={{ marginleft: '10px', marginLeft: '20px' }}>
                <OverlayTrigger
                  trigger="click"
                  key="right"
                  placement="left"
                  rootClose
                  overlay={overLayPopover}>
                  <FaEllipsisV />
                </OverlayTrigger>
              </div>
            );
          },
        },
      ].filter(Boolean),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, filters: tableFilters, sortBy },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        pageSize: PAGE_CONFIG.size,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / PAGE_CONFIG.size),
      data: data || [],
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const _filters = parseTableFilters(tableFilters);
    const sort = getSortBy(sortBy);
    const updatedFilters = {
      ..._filters,
      size: filters?.size,
      page: 0,
      sort: 'createdAt,desc',
    };
    setFilters(updatedFilters, 'replace');
  }, [tableFilters]);

  useEffect(() => {
    setFilters({ ...filters, size: filters?.size, page: pageIndex }, 'replace');
  }, [pageIndex]);

  return (
    <React.Suspense fallback={<Loader />}>
      <div {...getTableProps()} className="OrderAllocation table sticky">
        <div className="header" style={{ minWidth: '100%' }}>
          {headerGroups.map((headerGroup) => (
            <React.Fragment key={headerGroup.id}>
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr"
                style={{ display: 'flex', width: '100%' }}>
                {headerGroup.headers.map((column) => (
                  <div
                    key={column.id}
                    {...column.getHeaderProps()}
                    className="th border-right d-flex flex-row align-items-center"
                    style={{ width: '100%' }}>
                    {column.render('Header')}
                  </div>
                ))}
              </div>
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr filter-row"
                style={{ display: 'flex', width: '100%' }}>
                {headerGroup.headers.map((column) => (
                  <div
                    key={column.id}
                    {...column.getHeaderProps()}
                    className="th filter"
                    style={{ width: '100%' }}>
                    {column.Filter ? column.render('Filter') : null}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div
          {...getTableBodyProps({
            style: { height: 'calc(100vh - 370px)', width: '100%' },
          })}
          className="body">
          <Content
            loading={loading}
            emptyIcon={EmptyIcon}
            emptyResultMessage="There are no data for selected criteria"
            hasData={data?.length > 0}
            emptyMessage="No Data Found">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div
                  key={row.id}
                  {...row.getRowProps()}
                  className="tr"
                  style={{ display: 'flex', width: '100%' }}>
                  {row.cells.map((cell) => (
                    <div
                      key={cell.id}
                      {...cell.getCellProps()}
                      className="td"
                      style={{ width: '100%' }}>
                      {cell.render('Cell')}
                    </div>
                  ))}
                </div>
              );
            })}
          </Content>
        </div>
      </div>
      <PaginationComponent
        className="mt-3"
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        loading={loading && data?.length}
        gotoPage={gotoPage}
        currentPage={pageIndex}
        numberOfPages={pageOptions?.length}
        totalCount={totalCount}
      />
    </React.Suspense>
  );
};

export default OrderAllocationHoldList;
