const FilterParamMap = {
  AGGREGATOR: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    mobile: 'mobile.equals',
    businessName: 'businessName.equals',
    contactPersonName: 'contactPersonName.equals',
    addresses: 'regionIds',
    serviceable: 'serviceable.equals',
    accountManagerId: 'accountManagerId',
    includeDummy: 'includeDummy',
    id: 'id.in',
    status: 'status',
    sellerId: 'sellerId',
    spocId: 'spocId.equals',
    rsmId: 'rsmId.equals',
    gstRating: 'gstRating',
    osvRating: 'osvRating',
    totalRating: 'totalRating',
    nextListingDate: ['nextListingDate.greaterOrEqualThan', 'nextListingDate.lessOrEqualThan'],
    type: 'type',
    createdDate: ['createdDate.greaterOrEqualThan', 'createdDate.lessOrEqualThan'],
  },
  LISTINGS: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    status: 'status.in',
    items: 'itemIds',
    seller: 'sellerId.equals',
    sellerId: 'sellerId.equals',
    id: 'id.equals',
    createdOn: ['createdOn.greaterThanOrEqual', 'createdOn.lessThanOrEqual'],
    isBulkDeal: 'isBulkDeal.equals',
    recyclerName: 'recyclerName',
    sellerStatus: 'sellerStatus',
    suggestedPriceSource: 'suggestedPriceSource.equals',
    listingIdOrSellerName: 'listingIdOrSellerName',
    materialTypeId: 'materialTypeId.equals',
    itemIds: 'itemIds',
  },
  METRICS_LISTING: {
    sort: 'sort',
    status: 'status.in',
    items: 'itemIds',
    seller: 'sellerName',
    sellerId: 'sellerId.equals',
    id: 'id.equals',
    createdOn: ['createdOn.greaterThanOrEqual', 'createdOn.lessThanOrEqual'],
    materialTypeIds: 'materialTypeIds',
  },
  PO: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    recyclerName: 'recyclerName',
    itemDetails: 'itemIds',
    sellerName: 'sellerId.equals',
    id: 'id.equals',
    poNumber: 'poNumber.equals',
    shipFromAddressId: 'regionIds',
    mpPurchaseOrderItems: 'itemIds',
    hasBalanceQty: 'hasBalanceQty',
    status: 'status.in',
    createdDate: ['createdDate.greaterThanOrEqual', 'createdDate.lessThanOrEqual'],
    expiryDate: ['expiryDate.greaterThanOrEqual', 'expiryDate.lessThanOrEqual'],
    allowedShipmentFromCityId: 'allowedShipmentFromCityId',
    requisitionId: 'requisitionId.equals',
    searchKey: 'searchKey',
  },
  SALE_ORDER: {
    id: 'id.equals',
    sort: 'sort',
    page: 'page',
    size: 'size',
    items: 'itemIds',
    status: 'status.in',
    statusOnlyActive: 'status.notIn',
    shipFromAddressDTO: 'regionIds',
    destination: 'destinationIds',
    transporterName: 'transporterIds',
    recyclerName: 'buyerId.equals',
    seller: 'sellerId.equals',
    sellerId: 'sellerId.equals',
    kamUserId: 'kamUserId.equals',
    saleOrderDate: ['saleOrderDate.greaterThanOrEqual', 'saleOrderDate.lessThanOrEqual'],
    includeDummy: 'includeDummy',
    vehicleNumber: 'vehicleNumber',
    fetchAll: 'fetchAll',
    accountsView: 'accountsView',
    salesInvoiceNo: 'salesInvoiceNumber.equals',
    pickUpScheduledAt: [
      'pickUpScheduledAt.greaterThanOrEqual',
      'pickUpScheduledAt.lessThanOrEqual',
    ],
    documentStatus: 'documentStatus.in',
    lastModified: ['lastModified.greaterThanOrEqual', 'lastModified.lessThanOrEqual'],
    trackingStatus: 'trackingStatus',
    tripStatus: 'tripStatus',
    trackingView: 'trackingView',
    driverContactNumber: 'driverContactNumber',
    truckPlacing: 'truckPlacing',
    isResell: 'isResell',
    createdDate: ['shipmentCreatedDate.greaterOrEqualThan', 'shipmentCreatedDate.lessOrEqualThan'],
    buyerNoteStatus: 'buyerNoteStatus.equals',
    sellerNoteStatus: 'sellerNoteStatus.equals',
  },
  LOGISTICS: {
    sort: 'sort',
    page: 'page',
    size: 'size',
  },
  CREDIT_LIMIT: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    id: 'id.in',
    recyclerId: 'recyclerId.in',
  },
  RECYCLE_PAYMENT_TERM: {
    isGlobal: 'isGlobal.equals',
  },
  RECYCLER: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    businessName: 'businessName',
    mobile: 'mobile',
    regionId: 'regionIds',
    itemIds: 'itemIds',
    id: 'id.in',
    pointOfContact: 'pointOfContactName',
    cityId: 'cityId',
    latestDate: ['latestDate.greaterThanOrEqual', 'latestDate.lessThanOrEqual'],
    nextRequisitionDate: [
      'nextRequisitionDate.greaterThanOrEqual',
      'nextRequisitionDate.lessThanOrEqual',
    ],
    materialId: 'materialTypeIds',
    categoryId: 'categoryId',
    pointOfContactId: 'pointOfContact.in',
    createdDate: ['createdDate.greaterThanOrEqual', 'createdDate.lessThanOrEqual'],
    businessRole: 'businessRole',
  },
  RECYCLER_LEADS: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    businessName: 'businessName.contains',
    mobile: 'mobile.contains',
    gstin: 'gstin.contains',
    pan: 'pan.contains',
    id: 'id.in',
    name: 'contactPersonName.contains',
    createdDate: ['createdDate.greaterOrEqualThan', 'createdDate.lessOrEqualThan'],
  },
  REQUISITION: {
    size: 'size',
    page: 'page',
    id: 'id.equals',
    sort: 'sort',
    itemId: 'itemId.equals',
    itemIds: 'itemIds',
    status: 'status.in',
    createdOn: ['createdOn.greaterThanOrEqual', 'createdOn.lessThanOrEqual'],
    buyerName: 'buyerName',
    regionIds: 'regionIds',
    qty: 'qty.greaterThanOrEqual',
    listingId: 'listingId.equals',
    source: 'source.equals',
    pointOfContact: 'pointOfContactName',
    searchKey: 'searchKey',
    recyclerId: 'recyclerId.equals',
    materialTypes: 'materialTypes.in',
  },
  SALESORDER: {
    size: 'size',
    page: 'page',
    id: 'id.equals',
    sort: 'sort',
    itemId: 'itemId.equals',
    itemIds: 'itemIds',
    status: 'status.in',
    createdDate: ['createdDate.greaterThanOrEqual', 'createdDate.lessThanOrEqual'],
    // buyerName: 'buyerName',
    regionIds: 'regionIds',
    quantity: 'quantity.greaterThanOrEqual',
    listingId: 'listingId.equals',
    createdFrom: 'createdFrom.equals',
    // pointOfContact: 'pointOfContactName',
    searchKey: 'searchKey',
    buyerName: 'buyerId.equals',
    materialTypes: 'materialTypes.in',
  },
  QUOTE: {
    size: 'size',
    page: 'page',
    id: 'id.equals',
    sellerName: 'sellerName',
    requisitionId: 'requisitionId',
    itemId: 'itemId.equals',
    sort: 'sort',
    status: 'status.in',
  },
  GST_FILINGS: {
    size: 'size',
    page: 'page',
    gstin: 'gstin.equals',
    legalName: 'legalName.equals',
    recommended: 'recommended.equals',
    status: 'status.in',
    sort: 'sort',
    m1gstr1: 'm1gstr1.equals',
    m2gstr1: 'm2gstr1.equals',
    m3gstr1: 'm3gstr1.equals',
    m4gstr1: 'm4gstr1.equals',
    m5gstr1: 'm5gstr1.equals',
    m6gstr1: 'm6gstr1.equals',
    m1gstr3b: 'm1gstr3b.equals',
    m2gstr3b: 'm2gstr3b.equals',
    m3gstr3b: 'm3gstr3b.equals',
    m4gstr3b: 'm4gstr3b.equals',
    m5gstr3b: 'm5gstr3b.equals',
    m6gstr3b: 'm6gstr3b.equals',
    globalFilter: 'globalFilter.equals',
    gstFilingPeriod: ['gstFilingPeriod.greaterThanOrEqual', 'gstFilingPeriod.lessThanOrEqual'],
    preferredMaterial: 'preferredMaterial',
  },
  NOTIFICATION: {
    size: 'size',
    page: 'page',
    userId: 'userId',
    sort: 'sort',
  },
  GSTR_2A: {
    size: 'size',
    page: 'page',
    sort: 'sort',
    gstin: 'gstin.equals',
    name: 'name.equals',
    invoiceNumber: 'invoiceNumber.equals',
    noteNumber: 'noteNumber.equals',
    filingDate: 'filingDate.equals',
    date: 'date.equals',
    fromPeriod: 'fromPeriod.equals',
    toPeriod: 'toPeriod.equals',
    noteType: 'noteType.equals',
    action: 'action',
  },
  GSTR_RECONCILE: {
    size: 'size',
    page: 'page',
    sort: 'sort',
    gstin: 'gstin.equals',
    businessName: 'name.equals',
    action: 'action',
    fromPeriod: 'fromPeriod.equals',
    toPeriod: 'toPeriod.equals',
    reconcileStatus: 'status.equals',
  },
  GST_PAYMENTS: {
    size: 'size',
    page: 'page',
    fromPeriod: 'fromPeriod.equals',
    toPeriod: 'toPeriod.equals',
    shipmentId: 'shipmentId.equals',
    aggregatorName: 'name.equals',
    recommendation: 'recommendation.equals',
    paymentStatus: 'paymentStatus.equals',
    gstr2AStatus: 'gst2aStatus.equals',
    gst3bStatus: 'gst3bStatus.equals',
    invoiceDate: 'invDate.equals',
  },
  PAYMENT_TERM: {
    size: 'size',
    page: 'page',
    sort: 'sort',
    userId: 'userId',
    paymentTermEvents: 'paymentTermEvents.in',
    isGlobal: 'isGlobal.equals',
    stateId: 'stateId.equals',
    cityId: 'cityId.equals',
    categoryIds: 'categoryIds',
    materialIds: 'materialIds',
  },
  CAMPAIGNS: {
    size: 'size',
    page: 'page',
    sort: 'sort',
    status: 'status.equals',
    id: 'campaignId.equals',
    name: 'name.equals',
    startDate: 'startDate.equals',
    endDate: 'endDate.equals',
    totalSellers: 'totalSellers.equals',
    usedSellers: 'usedSellers.equals',
    unUsedSellers: 'unUsedSellers.equals',
    viewedSellers: 'viewedSellers.equals',
  },
  CAMPAIGNS_UPDATE: {
    status: 'status',
    campaignId: 'campaignId',
    date: 'date',
    businessName: 'sellerName.equals',
    items: 'itemId.equals',
    size: 'size',
    page: 'page',
    sellerStatus: 'sellerStatus',
    mobile: 'mobile.equals',
    addresses: 'regionID.equals',
  },
  FIND_USER: {
    regionId: 'regionId',
    quantity: 'quantity',
    itemId: 'itemId',
    sellerId: 'sellerId',
    materialId: 'materialId',
    categoryId: 'categoryId',
    name: 'name',
  },
  RECEIVABLES_SUMMARY: {
    customerId: 'customerId.equals',
    material: 'material.equals',
    businessName: 'businessName.equals',
    paymentId: 'paymentId.equals',
    paymentTerm: 'paymentTerm.equals',
    date: ['createdAt.greaterThanOrEqual', 'createdAt.lessThanOrEqual'],
    size: 'size',
    page: 'page',
    referenceNumber: 'referenceNumber.equals',
    paymentNumber: 'paymentNumber.equals',
    sort: 'sort',
    invoiceNumber: 'invoiceNumber.equals',
    shipmentId: 'shipmentId.equals',
    recyclerId: 'recyclerId.equals',
    poId: 'poId.equals',
    creditNoteNumber: 'creditNoteNumber.equals',
    invoiceCreatedAt: ['invoiceCreatedAt.greaterThanOrEqual', 'invoiceCreatedAt.lessThanOrEqual'],
  },
  PAYMENTS_INFO: {
    shipmentId: 'shipmentId.equals',
    status: 'status.equals',
    level: 'level.equals',
    materialTypeId: 'materialType.equals',
    seller: 'sellerId.equals',
    size: 'size',
    page: 'page',
    sort: 'sort',
    shipmentDate: ['shipmentDate.greaterThanOrEqual', 'shipmentDate.lessThanOrEqual'],
    aggregatorPaymentDate: ['paymentDate.greaterThanOrEqual', 'paymentDate.lessThanOrEqual'],
    transporterPaymentDueDate: [
      'transporterPaymentDueDate.greaterThanOrEqual',
      'transporterPaymentDueDate.lessThanOrEqual',
    ],
    materialStatus: 'materialStatus.equals',
    gstStatus: 'gstStatus.equals',
    materialStatusFilterFor: 'materialStatusFilterFor',
    gstStatusFilterFor: 'gstStatusFilterFor',
    transporterId: 'transporterId.equals',
    dispatchedDate: ['dispatchedDate.greaterThanOrEqual', 'dispatchedDate.lessThanOrEqual'],
    zohoStatus: 'zohoStatus.equals',
    paymentTermEvent: 'paymentTermEvent.equals',
  },
  MARKETPLACE_LOGS: {
    entity: 'entity.equals',
    logType: 'logType.equals',
    entityId: 'entityId.equals',
    size: 'size',
    page: 'page',
    sort: 'sort',
  },
  LEADS: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    gstin: 'gstin',
    businessName: 'businessName.equals',
    contact: 'mobileNumber.equals',
    OSVstatus: 'osvStatus.equals',
    LeadStatus: 'leadStatus.equals',
  },
  CITY: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    city: 'city',
    state: 'state',
    pincode: 'pincode',
  },

  USERS: {
    mobile: 'mobile.contains',
    sort: 'sort',
    page: 'page',
    size: 'size',
    sizeNew: 'size',
    firstName: 'name.contains',
    email: 'email.contains',
    category: 'category.contains',
    authorities: 'role.equals',
    zone: 'zone',
    reportingTo: 'reportingTo.equals',
    activated: 'active.equals',
    logType: 'logType',
    userId: 'userId',
    locationDetails: 'locationDetails',
    isAvailable: 'isAvailable',
  },

  ITEMS: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    id: 'id.equals',
    materialId: 'materialId',
    name: 'name.equals',
    active: 'active.in',
    categoryId: 'categoryId.equals',
    itemType: 'itemType.equals',
  },

  DAILY_PRICES: {
    categoryId: 'categoryId',
    customerType: 'customerType',
    createdDate: ['createdDate.greaterThanOrEqual', 'createdDate.lessThanOrEqual'],
    page: 'page',
    size: 'size',
    pointOfContactId: 'pointOfContact.in',
    businessName: 'businessName',
    businessRole: 'businessRole',
  },
  MANAGE_LOGISTICS: {
    sort: 'sort',
    size: 'size',
    page: 'page',
    fromCityId: 'fromCity.equals',
    toCityId: 'toCity.equals',
  },
  USER_ACTIVITY: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    customerId: 'customerId.equals',
  },
  TRANSPORTER: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    name: 'name.equals',
    mobileNo: 'mobile.equals',
    contactPerson: 'contactPersonName.equals',
    address: 'sourceId.equals',
  },
  LOGISTICS_ITEM: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    itemName: 'itemName.equals',
    itemDescription: 'itemDescription.equals',
    hsnCode: 'hsnCode.equals',
    unit: 'unit.equals',
    taxRate: 'taxRate.in',
  },

  RECYKAL_MARGIN_QRF: {
    page: 'page',
    size: 'size',
    sort: 'sort',
    itemId: 'itemId.equals',
    stateId: 'stateId.equals',
    cityId: 'cityId.equals',
  },

  SEGMENTATION_PARAMS: {
    materialType: 'materialType.equals',
    aggregatorType: 'aggregatorType.equals',
    segmentName: 'segmentName',
    appliedConditionsOn: 'appliedConditionsOn',
    entityId: 'entityId.equals',
    sort: 'sort',
    size: 'size',
    page: 'page',
  },
  APP_LEADS: {
    customerId: 'customerId',
    materialName: 'materialName',
    bankStatus: 'bankStatus',
    agreementStatus: 'agreementStatus',
    isOsvRequired: 'isOsvRequired',
  },
  ORDER_ALLOCATION_PARAMS: {
    shipmentId: 'shipmentId.equals',
    orderStatus: 'orderStatus.in',
    documentStatus: 'documentStatus.in',
    spocId: 'spocId',
    createdAt: ['createdAt.greaterThanOrEqual', 'createdAt.lessThanOrEqual'],
    userId: 'userId.equals',
    assignedOn: ['assignedOn.greaterThanOrEqual', 'assignedOn.lessThanOrEqual'],
    completedOn: ['completedOn.greaterThanOrEqual', 'completedOn.lessThanOrEqual'],
    allocationStatus: 'status.equals',
    sort: 'sort',
    size: 'size',
    page: 'page',
    modifiedAt: ['modifiedAt.greaterThanOrEqual', 'modifiedAt.lessThanOrEqual'],
    modifiedBy: 'modifiedBy.equals',
  },

  MANAGE_UPDATES: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    vendorType: 'vendorType.in',
    type: 'type.in',
    title: 'title.contains',
    createdAt: ['createdAt.greaterThanOrEqual', 'createdAt.lessThanOrEqual'],
  },

  SALES_ORDERS: {
    sort: 'sort',
    page: 'page',
    size: 'size',
    shipmentCreationDetails: 'shipmentCreationDetails',
    itemId: 'itemId.equals',
    quantity: 'quantity',
    purchaseOrderId: 'purchaseOrderId',
    status: 'status.in',
    buyerId: 'buyerId.equals',
    id: 'id.equals',
    searchKey: 'searchKey',
  },
};

export default FilterParamMap;
