/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable */
/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  OverlayTrigger,
  ListGroup,
  Table,
  Popover,
  Col,
  Row,
  Image,
} from 'react-bootstrap';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import { flatMap, isEmpty } from 'lodash';
import * as _ from 'lodash';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Content, ColumnSort, PaginationComponent } from '../../shared/components';
import { Address, Items, OverlayCell, Region } from '../../shared/components/GenericComponent';
import EmptyIcon from '../../assets/img/item-empty.png';
import DownloadIcon from '../../assets/img/download.png';
import { InputColumnFilter, SelectColumnFilter } from '../../shared/components/FilterComponents';
import {
  convertToTableFilters,
  debounce,
  parseTableFilters,
  toCapitalize,
  toRupees,
} from '../../shared/utils/Helper';
import { PAGE_CONFIG } from '../../shared/constants/Constants';
import { useAsyncEffect } from '../../hooks';
import { useRecyclers } from '../../hooks/hooks';
import { getSortBy } from '../../shared/utils/filters.utils';
import { ReactComponent as IconFirstOrder } from '../../assets/img/first-order.svg';

const IndividualReceivablesTable = (props) => {
  const {
    recyclerLedger,
    recyclerList,
    loading,
    totalCount,
    onFilterChange,
    refresh,
    setRefresh,
  } = {
    ...props,
  };
  const history = useHistory();
  const [
    {
      value: { data: recyclers = [] },
      getPError,
      fetchingP,
      getValueP,
    },
    { doFetchRecycler },
  ] = useRecyclers();
  const reList = recyclerList;

  const defaultColumn = React.useMemo(
    () => ({
      Filter: InputColumnFilter,
    }),
    []
  );

  const queryParamsConfig = {
    sort: withDefault(StringParam, 'id,desc'),
  };

  const [filter, setFilter] = useQueryParams(queryParamsConfig);
  useEffect(() => {
    setAllFilters([]);
  }, []);

  useAsyncEffect(async () => {
    setAllFilters(convertToTableFilters(filter));
    await doFetchRecycler();
  }, []);

  useEffect(() => {
    applyFilters(filter);
  }, [filter]);

  const applyFilters = React.useCallback(
    debounce((newFilters) => {
      const latestFilters = { ...newFilters };
      onFilterChange(latestFilters);
    }, 100),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdDate',
        sticky: 'left',
        canSort: false,
        canFilter: true,
        width: '25%',
        Cell: (cellInfo) => {
          return cellInfo.value != null ? (
            <span style={{ textAlign: 'center' }}>
              {moment(new Date(cellInfo.value)).format('DD-MMM-YYYY')}
            </span>
          ) : (
            <span style={{ textAlign: 'center' }}>-</span>
          );
        },
      },
      {
        Header: 'Purchase Order',
        accessor: 'poId',
        canSort: false,
        canFilter: true,
        Cell: (cellInfo) => {
          const element =
            cellInfo.value != null ? (
              <Popover id={`popover-positioned-${'left'}`}>
                <Popover.Content>
                  <ListGroup variant="flush" defaultActiveKey="#link1">
                    <ListGroup.Item
                      style={{
                        textDecoration: 'underline',
                        color: '#0645ad',
                        backgroundColor: 'white',
                      }}
                      as={Link}
                      to={`/orders/saleorder/${cellInfo?.row?.original?.saleOrderId}`}
                      action>
                      {cellInfo?.row?.original?.saleOrderId}
                    </ListGroup.Item>
                  </ListGroup>
                </Popover.Content>
              </Popover>
            ) : (
              <span style={{ textAlign: 'center' }}>
                {cellInfo.value != null ? cellInfo.value : '-'}
              </span>
            );
          return (
            <div style={{ textAlign: 'center', color: 'rgb(6, 69, 173)' }}>
              <Row>
                <Col>
                  <OverlayTrigger
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose
                    overlay={element}>
                    <span className="float-center" style={{ color: 'rgb(6, 69, 173)' }}>
                      {cellInfo.value}
                    </span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </div>
          );
        },
      },
      {
        Header: 'Paper PO',
        accessor: 'paperPoNumber',
        canSort: false,
        canFilter: true,
        Cell: (cellInfo) => {
          return <span style={{ textAlign: 'center' }}>{cellInfo?.value}</span>;
        },
      },
      {
        Header: 'Transaction Type',
        accessor: 'transactionType',
        Cell: (cellInfo) => {
          return <span style={{ textAlign: 'center' }}>{cellInfo?.value}</span>;
        },
      },
      {
        Header: 'Document Type',
        accessor: 'documentType',
        Cell: (cellInfo) => {
          return <span style={{ textAlign: 'center' }}>{cellInfo?.value}</span>;
        },
      },
      {
        Header: 'Document ID',
        accessor: 'saleInvoiceNumber',
        Cell: (cellInfo) => {
          return (
            <span style={{ textAlign: 'center' }}>
              {cellInfo.value !== '' ? cellInfo.value : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: (cellInfo) => {
          return (
            <span style={{ textAlign: 'center' }}>
              {' '}
              {toRupees(cellInfo.value, false).toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
              })}
            </span>
          );
        },
      },
      {
        Header: 'Pending Amount',
        accessor: 'pendingAmount',
        Cell: (cellInfo) => {
          return (
            <span style={{ textAlign: 'center' }}>
              {' '}
              {toRupees(cellInfo.value, false).toLocaleString('en-IN', {
                style: 'currency',
                currency: 'INR',
              })}
            </span>
          );
        },
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        sticky: 'right',
        Cell: (cellInfo) => {
          return cellInfo.value != null ? (
            <span style={{ textAlign: 'center' }}>
              {moment(new Date(cellInfo.value)).format('DD-MMM-YYYY')}
            </span>
          ) : (
            <span style={{ textAlign: 'center' }}>-</span>
          );
        },
      },
    ],
    [recyclerLedger]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, filters, sortBy },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        // pageSize: PAGE_CONFIG.size,
      },
      defaultColumn,
      manualSortBy: true,
      manualPagination: true,
      // pageCount: Math.ceil(totalCount / PAGE_CONFIG.size),
      data: recyclerLedger?.length > 0 ? recyclerLedger : [],
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    const _filters = parseTableFilters(filters);
    const sort = getSortBy(sortBy);
    const updatedFilters = {
      ..._filters,
      // size: filters.size,
      // page: 0,
      sort,
    };
    setFilter(updatedFilters, 'replace');
  }, [filters, sortBy]);

  /* useEffect(() => {
    setFilter({ ...filter, size: filter.size, page: pageIndex }, 'replace');
  }, [pageIndex]); */

  function sortCheck(column) {
    return column.id === '';
  }

  function filterCheck(column) {
    return (
      column.id === '' ||
      column.id === 'paperPoNumber' ||
      column.id === 'amount' ||
      column.id === 'saleInvoiceNumber' ||
      column.id === 'pendingAmount' ||
      column.id === 'poId' ||
      column.id === 'documentType' ||
      column.id === 'transactionType'
    );
  }

  return (
    <div>
      <Table
        {...getTableProps()}
        className="table rcyl-table table-body-scroll animate__animated animate__fadeIn">
        <thead>
          {headerGroups.map((headerGroup, l) => (
            <React.Fragment key={l}>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, h) => (
                  <React.Fragment key={h}>
                    <th
                      style={{ textAlign: 'center' }}
                      {...column.getHeaderProps(
                        column?.canSort && sortCheck(column) ? column.getSortByToggleProps() : ''
                      )}>
                      <div
                        className="d-flex flex-row align-items-center"
                        style={{ justifyContent: 'center' }}>
                        {column.render('Header')}
                        {column?.canSort && sortCheck(column) ? (
                          <span className="ml-1">
                            <ColumnSort column={column} />
                          </span>
                        ) : null}
                      </div>
                    </th>
                  </React.Fragment>
                ))}
              </tr>
              <tr className="filter-row">
                {headerGroup.headers.map((column, idx) => (
                  <React.Fragment key={idx}>
                    <th
                      style={{ textAlign: 'center' }}
                      {...column.getHeaderProps({ style: { width: column.width } })}>
                      {column?.canFilter && filterCheck(column) ? column.render('Filter') : null}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </thead>
        <tbody {...getTableBodyProps({ style: { height: '58vh' } })}>
          <Content
            loading={loading}
            emptyIcon={EmptyIcon}
            hasData={recyclerLedger?.length && rows.length}
            isFiltered={filters?.length > 0}
            emptyResultMessage="There are no Records for the selected criteria"
            emptyMessage="There are no Records found.">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, k) => {
                      return (
                        <React.Fragment key={k}>
                          <td style={{ textAlign: 'center' }} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </Content>
        </tbody>
      </Table>
      <hr />
      <div className="font-regular d-none d-md-block">
        {rows.length > 0 ? `Total Count -${rows.length}` : ''}
      </div>
    </div>
  );
};

export default React.memo(IndividualReceivablesTable);
