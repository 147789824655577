export const REQUISITIONS_ID = 'Requisition Id';
export const FULLFILLED_ON = 'Fulfillment on';
export const ITEM_NAME = 'Item Name';
export const PRICE = 'Price';
export const rate = 'RATE';
export const ORDER_QTY = 'Order Quantity';
export const BOOKED_QTY = 'Booked Quantity';
export const DISPATCHED_QTY = 'Dispatched Quantity';
export const NEXT_LISTING_DATE = 'Next Listing Date';
export const LAST_LISTING_DATE = 'Last Listing Date';
export const APPROVED = 'Approved';
export const REJECTED = 'Rejected';
export const RECYCLER = 'RECYCLER';
export const SELLER = 'SELLER';
export const GROSS_MARGIN = 'Gross Margin';
export const CREDIT_LIMIT = 'Credit Limit';
export const AVAILABLE_CREDITS = 'Available Credits';

export const SELLER_DETAILS = 'Seller Details';
export const PREFERED_BUYER_DETAILS = 'Preferred Buyer Details';
export const ACTUAL_BUYER = 'Actual/Assigned Buyer';
export const MARGIN_DETAILS = 'Margin Details';
export const ITEM_DETAILS = 'Item Details';
export const TRANSACTION_ALERT = 'Transaction Alert';
export const BUYER_INFORMATION = 'Buyer information';
export const SALEORDER_INFORMATION = 'Saleorder Information';
export const TRUCK_DRIVER_DETAILS = 'Truck Details';
export const PO_DETAILS = 'PO Details';
export const PURCHASE_MANAGER_DETAILS = 'Purchase Manager Details';
export const ASSIGNED_LISTINGS_SALEORDERS = 'Assigned Listings and Sale Orders';

export const BUYER_DETAILS = 'Buyer Details';
export const PURCHASE_ORDER_DETAILS = 'Purchase Order Details';
export const LISTINGS = 'Listings';

export const VEHICLE_IMAGES = 'Vehicle Images';
export const WEIGHBRIDGE_DETAILS = 'Weigh-bridge Details';
export const SALES_INVOICE_EWAY_BILL = 'Sales Invoice & E-way Bill';

export const GROSS_MARGIN_PERCENT = 'Gross Margin Percent';

export const AGGREGATOR = 'AGGREGATOR';

export const SAVE = 'Save';

export const VERIFY = 'Verify';

export const BANK_DETAILS_NOT_FOUND = 'Bank details not found';

export const SUCCESS = 'SUCCESS';
export const KYC_DOCUMENT = 'KYC DOCUMENT';
export const REQUEST_CREATED = 'REQUEST_CREATED';
export const SALE_ORDER_ID = 'Sale Order ID';
export const SHIPMENTS = 'Shipments';
export const TARGETS = 'Sale Order Fulfillment List';
export const BUSINESS_DETAILS = 'Business Details';
export const PO_NUMBER = 'PO Number';
export const PO_DATE = 'PO Date';
export const ORDER_QUANTITY = 'Order Quantity';
export const SELLER_NAME = 'Seller Name';
export const POC_NAME = 'POC Name';
export const STATUS_PO = 'PO Status';
export const PAYMENT_TERMS_DOCUMENTS = 'Payment Terms & Documents';
export const GSTIN = 'GSTIN';
export const CREATED_DATE = 'Created Date';
export const PICKUP_ADDRESS = 'Pickup Address';
export const BILLING_ADDRESS = 'Billing Address';
export const HSN_CODE = 'HSN Code';
export const QC_REPORT = 'QC Report';
export const SELLER_PRICE = 'Seller Price';
export const BUYER_PRICE = 'Buyer Price';
export const TOTAL_VALUE = 'Total Value (₹)';
export const MATERIAL_IMAGE = 'Material Image';
export const SHIPMENT_ID = 'Shipment Id';
export const SHIPMENT_DATE = 'Shipment Date';
export const SALEORDER_ID = 'SaleOrder ID';
export const QUANTITY = 'Quantity';
export const TOTAL_PRICE = 'Total Price (₹)';
export const SHIPMENT_STATUS = 'Shipment Status';
